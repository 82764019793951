import { useState } from "react";
import { DataItem, ProbabilityModal } from "../modal/probabilityModal";
import { PercentilesButton } from "../table/components/percentilesButton";
import { DataFields } from "@/hooks/data/useSimpleInferenceData";
import { Side } from "@/app/data/api";
import { PriceType } from "@/types/types";
import { useUiMode } from "@/hooks/useUiMode";

export const PercentilesInfoCellContent = ({
  data,
}: {
  data: {
    bid: DataFields;
    dealer: DataFields;
    offer: DataFields;
  }
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { isIgUiMode } = useUiMode();

  const d: DataItem[] = isIgUiMode
    ? [
        {
          title: 'Bid G-Spd',
          quantiles: data.bid.gspread.quantiles,
          side: Side.bid,
          priceType: PriceType.GSpread,
        },
        { 
          title: 'Offer G-Spd', 
          quantiles: data.offer.gspread.quantiles, 
          side: Side.offer,
          priceType: PriceType.GSpread,
        },
        { 
          title: 'Bid YTM', 
          quantiles: data.bid.ytm.quantiles,
          side: Side.bid,
          priceType: PriceType.Ytm,
        },
        { 
          title: 'Bid Px', 
          quantiles: data.bid.price.quantiles,
          side: Side.bid,
          priceType: PriceType.Price,
        },
      ]
    : [
        {
          title: 'Bid Px',
          quantiles: data.bid.price.quantiles,
          side: Side.bid,
          priceType: PriceType.Price,
        },
        { 
          title: 'Offer Px', 
          quantiles: data.offer.price.quantiles, 
          side: Side.offer,
          priceType: PriceType.Price,
        },
        { 
          title: 'Bid YTM', 
          quantiles: data.bid.ytm.quantiles,
          side: Side.bid,
          priceType: PriceType.Ytm,
        },
        { 
          title: 'Bid G-Spd', 
          quantiles: data.bid.gspread.quantiles,
          side: Side.bid,
          priceType: PriceType.GSpread,
        },
      ]

  return (
    <>
      <PercentilesButton onClick={() => setModalOpen(true)} />

      <ProbabilityModal
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        data={d}
      />
    </>
  )
}