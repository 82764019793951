import { UIMode, UserPreferences, UserPreferencesApiResponse } from "@/types/types";
import { baseApi } from ".";
import { DEFAULT_USER_PREFERENCES } from "@/constants";
import { captureException } from "@sentry/react";

export const preferencesEndpoints = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    // getUserPreferences
    getUserPreferences: builder.query<UserPreferences, void>({
      query: () => '/preferences',
      transformResponse: (response: UserPreferencesApiResponse): UserPreferences => {
        if (!response) {
          return DEFAULT_USER_PREFERENCES;
        }

        const tableConfig = response.tableConfig ? JSON.parse(response.tableConfig) : undefined;

        return {
          ...DEFAULT_USER_PREFERENCES, 
          ...response,
          tableConfig,
          uiMode: response.uiMode === UIMode.Default_14_05_2024 ? UIMode.HighYield : response.uiMode, // show HY ui mode instead of 14_05_2024 as 14_05_2024 was only temporary
          // uiMode: DEFAULT_USER_PREFERENCES.uiMode, // TODO: remove this after HY/IG migration
          // uiMode: UIMode.HighYield
        };
      }
    }),
    // updateUserPreferences
    updateUserPreferences: builder.mutation<any, Partial<UserPreferences> & { version: string }>({
      query: ({ tableConfig, ...rest }) => ({
        url: '/preferences',
        method: 'PATCH',
        body: {
          ...rest,
          ...(tableConfig ? { tableConfig: JSON.stringify(tableConfig) } : undefined),
        },
      }),
      async onQueryStarted(preferences, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(preferencesEndpoints.util.updateQueryData('getUserPreferences', undefined, (prev) => {
          if (!prev) {
            return DEFAULT_USER_PREFERENCES;
          }

          return {
            ...prev,
            ...preferences,
          }
        }));

        try {
          const result = await queryFulfilled;
          dispatch(preferencesEndpoints.util.updateQueryData('getUserPreferences', undefined, (prev) => {
            if (!prev) {
              return { ...DEFAULT_USER_PREFERENCES, version: result.data.version };
            }
  
            return {
              ...prev,
              version: result.data.version,
            }
          }));
        } catch (e) {
          captureException(e);
          patchResult.undo();
        }
      }
    }),
  }),
});

export const { 
  useGetUserPreferencesQuery,
  useUpdateUserPreferencesMutation
} = preferencesEndpoints;
