import { PortfolioResponse, PortfolioValue } from "@/app/data/portfolios";
import { baseApi } from ".";
import { captureException } from "@sentry/core";

export const portfolioEndpoints = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getPortfolio: builder.query<PortfolioResponse, string>({
      query: (id) => `/objects/${id}`,
      keepUnusedDataFor: 10, // 10 seconds cache
      providesTags: ['PortfolioObject'],
    }),
    getPortfolioList: builder.query<PortfolioResponse[], void>({
      query: () => `/objects?type=portfolio`,
      providesTags: ['PortfolioObjectList'],
      keepUnusedDataFor: 10,
    }),
    createPortfolioObject: builder.mutation<PortfolioResponse, { name: string, positions: PortfolioValue['positions'] }>({
      query: ({ name, positions }) => ({
        url: '/objects',
        method: 'POST',
        body: {
          type: 'portfolio',
          metadata: JSON.stringify({ name }),
          value: { positions }
        },
      }),
      invalidatesTags: r => r ? ['PortfolioObjectList'] : [], // invalidate after success only
      async onQueryStarted( _, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;

          try {
            dispatch(
              portfolioEndpoints.util.updateQueryData('getPortfolioList', undefined, (prevData) => {
                if (!prevData) return [result.data];
                return [...prevData, result.data];
              })
            );
          } catch (e) {
            captureException(e);
            console.error(e);
          }
        } catch {}
      },
    }),
    updatePortfolioObject: builder.mutation<PortfolioResponse, {
      id: string,
      version: string,
      positions?: PortfolioValue['positions'],
      metadata?: { name: string },
    }>({
      query: ({ id, version, positions, metadata }) => ({
        url: '/objects',
        method: 'PATCH',
        body: {
          id,
          version,
          ...(metadata && { metadata: JSON.stringify(metadata) }),
          ...(positions ? { value: { positions } } : {}),
        },
      }),
      async onQueryStarted( { id, positions, metadata }, { dispatch, queryFulfilled }) {
        // sync data in store after query fullfilled, no need for refetch
        try {
          await queryFulfilled;
          

          try {
            const updatePortfolio = (p: PortfolioResponse) => {
              return {
                ...p,
                ...(metadata && { metadata: JSON.stringify(metadata) }),  // api works in a way that if metadata or positions are not provided it return those keys as empty strings but actually tehre is a value on the server
                value: {
                  ...p.value,
                  positions: positions || (p.value ? p.value.positions : p.value),
                }
              }
            }

            // update portfolio item
            dispatch(
              portfolioEndpoints.util.updateQueryData('getPortfolio', id, updatePortfolio)
            );

            // update portfolio item in the list
            dispatch(
              portfolioEndpoints.util.updateQueryData('getPortfolioList', undefined, (prevData) => {
                return prevData.map(p => {
                  return p.id === id ?  updatePortfolio(p) : p
                })
              })
            );
          } catch (e) {
            captureException(e);
            console.error(e);
          }

        } catch {};
      },
    }),

  }),
});

export const {
  useGetPortfolioQuery,
  useLazyGetPortfolioQuery,
  useGetPortfolioListQuery,
  useCreatePortfolioObjectMutation,
  useUpdatePortfolioObjectMutation,
} = portfolioEndpoints;


