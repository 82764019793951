import { ColumnConfigMapType, PriceType, UIMode } from "@/types/types";
import { Side } from "../data/api";
import { PercentilesButton } from "../components/table/components/percentilesButton";

export const NO_POSITIONS_FOR_FILTERS = 'No positions found matching filter criteria';

export enum PortfolioColumn {
  Link = 'Link',
  Alert = 'Alert',
  Cusip = 'Cusip',
  Ticker = 'Ticker',
  Coupon = 'Coupon',
  Maturity = 'Maturity',
  AmountOutstanding = 'AmountOutstanding',
  SAndPRating = 'SAndPRating',
  Series = 'Series',
  Percentiles = 'Percentiles',
  BidGSpd = 'BidGSpd',
  BidGSpdTime = 'BidGSpdTime',
  OfferGSpd = 'OfferGSpd',
  BidGSpdChange = 'BidGSpdChange',
  BYTM = 'BYTM',
  BYTMChange = 'BYTMChange',
  BidPx = 'BidPx',
  BidPxTime = 'BidPxTime',
  OfferPx = 'OfferPx',
  BidPxChange = 'BidPxChange',
  BidGspdPrev = 'BidGspdPrev',
  BidGspdPrevTime = 'BidGspdPrevTime',
  BYTMPrev = 'BYTMPrev',
  BYTMPrevTime = 'BYTMPrevTime',
  BidPxPrev = 'BidPxPrev',
  BidPxPrevTime = 'BidPxPrevTime',
  Remove = 'Remove',
}

// text for column titles
export const PortfolioColumnTitle: Record<PortfolioColumn, JSX.Element | string> = {
  [PortfolioColumn.Link]: '',
  [PortfolioColumn.Alert]: '',
  [PortfolioColumn.Cusip]: 'Cusip',
  [PortfolioColumn.Ticker]: 'Ticker',
  [PortfolioColumn.Coupon]: 'Coupon',
  [PortfolioColumn.Maturity]: 'Maturity',
  [PortfolioColumn.AmountOutstanding]: 'Outstanding',
  [PortfolioColumn.SAndPRating]: 'S&P',
  [PortfolioColumn.Series]: 'Series',
  [PortfolioColumn.Percentiles]: <PercentilesButton />,
  [PortfolioColumn.BidGSpd]: 'Bid G-Spd',
  [PortfolioColumn.BidGSpdTime]: 'Bid G-Spd Time',
  [PortfolioColumn.OfferGSpd]: 'Offer G-Spd',
  [PortfolioColumn.BidGSpdChange]: 'Bid G-Spd Chng',
  [PortfolioColumn.BYTM]: 'B YTM',
  [PortfolioColumn.BYTMChange]: 'B YTM Chng',
  [PortfolioColumn.BidPx]: 'Bid Px',
  [PortfolioColumn.BidPxTime]: 'Bid Px Time',
  [PortfolioColumn.OfferPx]: 'Offer Px',
  [PortfolioColumn.BidPxChange]: 'Bid Px Chng',
  [PortfolioColumn.BidGspdPrev]: 'Bid G-Spd {prevPeriod}',
  [PortfolioColumn.BidGspdPrevTime]: 'Bid G-Spd Previous Time',
  [PortfolioColumn.BYTMPrev]: 'B YTM {prevPeriod}',
  [PortfolioColumn.BYTMPrevTime]: 'B YTM Previous Time',
  [PortfolioColumn.BidPxPrev]: 'Bid Px {prevPeriod}',
  [PortfolioColumn.BidPxPrevTime]: 'Bid Px Previous Time',
  [PortfolioColumn.Remove]: '',
}

export const PortfolioColumnPrintTitle: Record<PortfolioColumn, string> = {
  ...PortfolioColumnTitle as Record<PortfolioColumn, string>,
  [PortfolioColumn.BidGspdPrev]: 'Bid G-Spd Previous',
  [PortfolioColumn.BYTMPrev]: 'B YTM Previous',
  [PortfolioColumn.BidPxPrev]: 'Bid Px Previous',
}



export const IGPortfolioColumnOrder: PortfolioColumn[] = [
  PortfolioColumn.Link,
  PortfolioColumn.Alert,
  PortfolioColumn.Cusip,
  PortfolioColumn.Ticker,
  PortfolioColumn.Coupon,
  PortfolioColumn.Maturity,
  PortfolioColumn.SAndPRating,
  PortfolioColumn.Series,
  PortfolioColumn.AmountOutstanding,
  PortfolioColumn.Percentiles,
  PortfolioColumn.BidGSpd,
  PortfolioColumn.OfferGSpd,
  PortfolioColumn.BidGSpdChange,
  PortfolioColumn.BYTM,
  PortfolioColumn.BYTMChange,
  PortfolioColumn.BidPx,
  PortfolioColumn.BidPxChange,
  PortfolioColumn.BidGspdPrev,
  PortfolioColumn.BYTMPrev,
  PortfolioColumn.BidPxPrev,
  PortfolioColumn.Remove,
] 

export const HYPortfolioColumnOrder: PortfolioColumn[] = [
  PortfolioColumn.Link,
  PortfolioColumn.Alert,
  PortfolioColumn.Cusip,
  PortfolioColumn.Ticker,
  PortfolioColumn.Coupon,
  PortfolioColumn.Maturity,
  PortfolioColumn.SAndPRating,
  PortfolioColumn.Series,
  PortfolioColumn.AmountOutstanding,
  PortfolioColumn.Percentiles,
  PortfolioColumn.BidPx,
  PortfolioColumn.OfferPx,
  PortfolioColumn.BidPxChange,
  PortfolioColumn.BYTM,
  PortfolioColumn.BYTMChange,
  PortfolioColumn.BidGSpd,
  PortfolioColumn.BidGSpdChange,
  PortfolioColumn.BidPxPrev,
  PortfolioColumn.BYTMPrev,
  PortfolioColumn.BidGspdPrev,
  PortfolioColumn.Remove,
]



export const ColumnsOrderConfig: Record<UIMode, PortfolioColumn[]> = {
  [UIMode.InvestmentGrade]: IGPortfolioColumnOrder,
  [UIMode.HighYield]: HYPortfolioColumnOrder,
  [UIMode.Default_14_05_2024]: [],
}


export const ColumnConfigMap: ColumnConfigMapType<PortfolioColumn> = {
  [PortfolioColumn.Link]: null,
  [PortfolioColumn.Alert]: null,
  [PortfolioColumn.Cusip]: null,
  [PortfolioColumn.Ticker]: null,
  [PortfolioColumn.Coupon]: null,
  [PortfolioColumn.Maturity]: null,
  [PortfolioColumn.SAndPRating]: null,
  [PortfolioColumn.Series]: null,
  [PortfolioColumn.AmountOutstanding]: null,
  [PortfolioColumn.Percentiles]: null,
  [PortfolioColumn.BidGSpd]: {
    side: Side.bid,
    priceType: PriceType.GSpread,
  },
  [PortfolioColumn.BidGSpdTime]: null,
  [PortfolioColumn.OfferGSpd]: {
    side: Side.offer,
    priceType: PriceType.GSpread,
  },
  [PortfolioColumn.BidGSpdChange]: {
    side: Side.bid,
    priceType: PriceType.GSpread,
  },
  [PortfolioColumn.BYTM]: {
    side: Side.bid,
    priceType: PriceType.Ytm,
  },
  [PortfolioColumn.BYTMChange]: {
    side: Side.bid,
    priceType: PriceType.Ytm,
  },
  [PortfolioColumn.BidPx]: {
    side: Side.bid,
    priceType: PriceType.Price,
  },
  [PortfolioColumn.BidPxTime]: null,
  [PortfolioColumn.OfferPx]: {
    side: Side.offer,
    priceType: PriceType.Price,
  },
  [PortfolioColumn.BidPxChange]: {
    side: Side.bid,
    priceType: PriceType.Price,
  },
  [PortfolioColumn.BidGspdPrev]: {
    side: Side.bid,
    priceType: PriceType.GSpread,
  },
  [PortfolioColumn.BidGspdPrevTime]: null,
  [PortfolioColumn.BYTMPrev]: {
    side: Side.bid,
    priceType: PriceType.Ytm,
  },
  [PortfolioColumn.BYTMPrevTime]: null,
  [PortfolioColumn.BidPxPrev]: {
    side: Side.bid,
    priceType: PriceType.Price,
  },
  [PortfolioColumn.BidPxPrevTime]: null,
  [PortfolioColumn.Remove]: null,
}