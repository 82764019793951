import { memo, useCallback, useMemo, useState } from "react";
import { Bond } from "../data/bondIndex";
import { RfqLabelMap } from "./bond.constants";
import { useUiMode } from "@/hooks/useUiMode";
import { useBondsInferenceRequests } from "@/hooks/data/useBondsInferenceRequests";
import useInferences from "../data/useInferences";
import { PriceType } from "@/types/types";
import { AtsIndicator, Side } from "../data/api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { formatPrice, toFixed } from "@/utils/number.utils";
import { Checkbox } from "../components/checkbox";
import { range } from "lodash";
import Loading from "../loading";
import { interpolatePercentileByValue } from "@/utils/inference/percentile.utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
);

const colors = {
  bid: '#3FDA54',
  offer: '#E25270',
  xGrid: '#5D5F9D',
  yGrid: '#2E65A0',
  axisTitle: `#8183B3`,
}


const EMPTY_ARRAY = [] as number[];


// Chance to fill chart
export const CTFChart = memo(({
  bond,
  quantity,
  priceType,
}: {
  bond: Bond;
  quantity: number;
  priceType: PriceType;
}) => {
  const { uiMode, isIgUiMode } = useUiMode();
  const [activeLines, setActiveLines] = useState({
    bid: true,
    offer: true,
  });



  // get current bond inference
  // const rfqLabelMap = RfqLabelMap[uiMode];
  const rfqLabelMap = useMemo(() => {
    return {
      [Side.bid]: [priceType],
      [Side.offer]: [priceType],
      [Side.dealer]: []
    }
  }, [priceType])
  const bonds = useMemo(() => [bond], [bond]);
  const getQuantity = useCallback(() => quantity, [quantity]);
  const inferenceRequests = useBondsInferenceRequests({
    bonds,
    rfqLabelMap,
    getQuantity,
  });
  const [getInference] = useInferences(inferenceRequests);
  const bidInference = getInference(bond.figi, AtsIndicator.N, quantity, Side.bid, priceType)
  const offerInference = getInference(bond.figi, AtsIndicator.N, quantity, Side.offer, priceType)

  const bidPrices = bidInference?.[priceType] || EMPTY_ARRAY;
  const offerPrices = offerInference?.[priceType] || EMPTY_ARRAY;

  // if (isIgUiMode) {
  //   bidPrices.reverse();
  //   offerPrices.reverse();
  // }

  function getStepSize(rangeStart: number, rangeEnd: number, type: PriceType) {
    const diff = Math.abs(rangeEnd - rangeStart);
    switch (type) {
      case PriceType.GSpread:
        return diff < 5
          ? 0.125
          : diff < 20
            ? 0.25
            : diff < 40
              ? 0.5
              : 1
      default:
        return diff < 1
          ? 0.005
          : diff < 3
            ? 0.01
            : diff < 10
              ? 0.05
              : 0.1
    }

  }

  const [bidData, offerData] = useMemo(() => {
    const isIgPrice = priceType === PriceType.GSpread || priceType === PriceType.Ytm;

    // bid data
    const bidStart = bidPrices[0];
    const bidEnd = bidPrices[bidPrices.length - 1];
    const bidStep = getStepSize(bidStart, bidEnd, priceType);
    const bidData = range(bidStart, bidEnd, bidStep).map(price => {
      const percentile = interpolatePercentileByValue(price, bidPrices) * 100;
      return {
        x: price,
        y: isIgPrice ? 100 - percentile : percentile,
      }
    })

    if (bidData.length > 0 && bidData[bidData.length - 1].y !== 95) {
      if (isIgPrice) {
        bidData.push({ x: bidPrices[bidPrices.length - 1], y: 5 })
      } else {
        bidData.push({ x: bidPrices[bidPrices.length - 1], y: 95 })
      }
    }

    // offer data
    const offerStart = offerPrices[0];
    const offerEnd = offerPrices[offerPrices.length - 1];
    const offerStep = getStepSize(offerStart, offerEnd, priceType);
    const offerData = range(offerStart, offerEnd, offerStep).map(price => {
      const percentile = interpolatePercentileByValue(price, offerPrices) * 100;
      return {
        x: price,
        y: isIgPrice ? percentile : 100 - percentile,
      }
    })

    if (offerData.length > 0 && offerData[offerData.length - 1].y !== 5) {
      if (isIgPrice) {
        offerData.push({ x: offerPrices[offerPrices.length - 1], y: 95 })
      } else { 
        offerData.push({ x: offerPrices[offerPrices.length - 1], y: 5 })
      }
    }

    return [bidData, offerData]
  }, [bidPrices, offerPrices, priceType])

  const data = useMemo(() => {

    return {
      datasets: [
        {
          label: 'Bid',
          data: bidData,
          borderColor: colors.bid,
          pointBorderColor: colors.bid,
          pointBackgroundColor: colors.bid,
          pointBorderWidth: 0,
          pointRadius: 1,
          showLine: true,
          hidden: !activeLines.bid,

        },

        {
          label: 'Offer',
          data: offerData,
          borderColor: colors.offer,
          pointBorderColor: colors.offer,
          pointBackgroundColor: colors.offer,

          pointBorderWidth: 0,
          pointRadius: 1,
          showLine: true,
          hidden: !activeLines.offer,
        },
      ],
    } as ChartData<'scatter'>
  }, [bidData, offerData, activeLines])

  const options = useMemo(() => {
    const values = [...bidPrices, ...offerPrices];
    const min = Math.min(...values);
    const max = Math.max(...values);
    const xAxisTitle = priceType === PriceType.GSpread ? 'G-Spread' : priceType === PriceType.Ytm ? 'YTM' : 'Quote Price';

    const options: ChartOptions<'scatter'> = {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: (c) => {
              const labelSuffix = priceType === PriceType.GSpread 
                ? 'G-Spread' 
                : priceType === PriceType.Ytm 
                  ? 'YTM'
                  : 'Price';
            
              const valueSuffix = priceType === PriceType.GSpread ? 'bps' : '';
              

              return [
                `${c.dataset.label} ${labelSuffix}: ${formatPrice(c.parsed.x, priceType)} ${valueSuffix}`,
                `Chance to Fill: ${toFixed(c.parsed.y, 2)}%`,
              ]
            }
          },
          caretPadding: 10,
          displayColors: false,
          position: 'nearest'
        },
        legend: {
          display: false,
        }
      },
      scales: {
        x: {
          min: min - 0.01,
          max: max + 0.01,
          grid: {
            display: true,
            color: colors.xGrid
          },

          title: {
            display: true,
            text: xAxisTitle,
            color: colors.axisTitle,
          },
        },
        y: {
          min: 0,
          max: 100,
          grid: {
            display: true,
            color: colors.yGrid,
          },
          ticks: {
            maxTicksLimit: 10,
            callback: function (value) {
              return toFixed(value, 1) + '%';
            }
          },
          title: {
            display: true,
            text: 'Chance to Fill',
            color: colors.axisTitle,
            align: 'end',
          },
        },
      }
    };

    return options;
  }, [bidPrices, offerPrices, priceType])

  if (!bidInference || !offerInference) {
    return <Loading className="mt-[2.5rem]" />
  }

  return (
    <div className="relative pt-4 h-full">

      {/* Bid offer toggle */}
      <div className="flex justify-end items-center gap-4 mr-5 absolute right-0 -translate-y-full top-0 h-[2.0625rem]">
        <Checkbox
          label="Bid"
          ariaLabel='Show/Hide Bid Line'
          checked={activeLines.bid}
          onClick={() => {
            setActiveLines({
              ...activeLines,
              bid: !activeLines.bid,
            })
          }}
        />

        <Checkbox
          label="Offer"
          ariaLabel='Show/Hide Offer Line'
          checked={activeLines.offer}
          onClick={() => {
            setActiveLines({
              ...activeLines,
              offer: !activeLines.offer,
            })
          }}
        />
      </div>

      {/* Chart */}
      <Scatter options={options} data={data} />
    </div>
  )
})