import { useCallback, useEffect, useMemo, useState } from "react";
import { AtsIndicator } from "../data/dataProvider";
import useBond from '../data/useBond';
import useTrades from "../data/useTrades";
import Loading from "../loading";
import BondSummary from "./bondSummary";
import Chart from './chart';
import StyledListBox from "../components/listbox/styledListbox";
import RecentTrades from './recentTrades';
import { PriceType } from "@/types/types";
import { useUiMode } from "@hooks/useUiMode";
import { PriceTypeLabel, RfqLabelMap } from "./bond.constants";
import { CTFChart } from "./ctfChart";
import { useBondsInferenceRequests } from "@/hooks/data/useBondsInferenceRequests";
import useInferences from "../data/useInferences";
import { Tabs } from "../components/tabs/tabs";
import { TabsHeader } from "../components/tabs/tabsHeader";
import { Tab } from "../components/tabs/tab";
import { Tooltip } from "../components/tooltip";

const xAxisOptions = [
  'Evenly Spaced Trades',
  'Date'
] as const;

const tradeCountOptions = [
  '10',
  '20',
  '30',
  '40',
  '50',
  '60',
  '70',
  '80',
  '90',
  '100'
] as const;

const quantityOptions = [
  '0.001 MM',
  '0.01 MM',
  '0.1 MM',
  '0.25 MM',
  '0.5 MM',
  '1 MM',
  '2 MM',
  '3 MM',
  '4 MM',
  '5 MM'
] as const;

const priceTypeOptions = Object.values(PriceType);

const Bond = () => {
  const bond = useBond();

  const { isIgUiMode, uiMode } = useUiMode();
  const [atsIndicator] = useState<AtsIndicator>(AtsIndicator.N);
  const [chartType, setChartType] = useState<'ctf' | 'time'>('time')
  const [tradeCount, setTradeCount] = useState<(typeof tradeCountOptions)[number]>('20');
  const [xAxisKey, setXAxisKey] = useState<(typeof xAxisOptions)[number]>('Evenly Spaced Trades');
  const [quantity, setQuantity] = useState<(typeof quantityOptions)[number]>('1 MM');
  const [priceType, setPriceType] = useState(isIgUiMode ? PriceType.GSpread : PriceType.Price);
  const quantityNumber = (+quantity.slice(0, -3)) * 1000000;

  // get the max possible trades upfront (negligible server load difference and simplifies UI logic)
  const maxTrades = useTrades(bond?.figi, Math.max(...tradeCountOptions.map(v => +v)));
  const trades = useMemo(() => maxTrades.slice(-tradeCount), [maxTrades, tradeCount]);

  useEffect(() => {
    setPriceType(isIgUiMode ? PriceType.GSpread : PriceType.Price);
  }, [isIgUiMode])

  if (!bond) {
    return <Loading className="mt-[2.5rem]" />;
  }

  const isTimeChart = chartType === 'time';

  return (
    <div className="flex flex-col gap-[0.625rem] items-center pt-[0.625rem] px-[0.625rem] w-full">
      <BondSummary bond={bond} links={false} quantity={quantityNumber} />
      <div className="bg-[#0A0B11] gap-[0.625rem] grid grid-cols-1 grid-rows-[auto,auto,auto] pb-[0.625rem] pt-[1.25rem] px-[0.625rem] rounded-[0.625rem] w-full lg:grid-cols-[auto,1fr] lg:grid-rows-[auto,1fr] lg:h-[calc(100vh-26.1rem)] lg:px-[0.9375rem]">
        <div className="flex gap-[0.625rem] items-center flex-wrap w-full flex-row lg:col-start-2 lg:pl-[0.625rem] lg:row-start-1">
          <div className="flex-1 min-w-[40%] sm:min-w-0">
            <StyledListBox value={xAxisKey} selectValue={setXAxisKey} values={xAxisOptions} disabled={!isTimeChart} />
          </div>
          <div className="flex-1 min-w-[40%] sm:min-w-0">
            <StyledListBox value={tradeCount} selectValue={setTradeCount} values={tradeCountOptions} disabled={!isTimeChart} />
          </div>
          <div className="flex-1 min-w-[40%] sm:min-w-0">
            <StyledListBox value={quantity} selectValue={setQuantity} values={quantityOptions} />
          </div>
          <div className="flex-1 min-w-[40%] sm:min-w-0">
            <StyledListBox
              value={priceType}
              selectValue={setPriceType}
              values={priceTypeOptions}
              optionContent={(v) => PriceTypeLabel[v]}
              buttonContent={({ value }) => <span className="text-[#FBFBFD] truncate w-full">{PriceTypeLabel[value]}</span>}
            />
          </div>
        </div>
        <div className=" -mr-[0.3125rem] overflow-hidden lg:col-start-2  lg:-mr-[0.625rem] lg:row-start-2">
          <div className="lg:pl-[0.625rem]">
            <Tabs 
              active={chartType} 
              onChange={(t) => setChartType(t)}
              variant="row"
            >
              <TabsHeader>
                <Tab value={'time'}>Time</Tab>
                <Tooltip
                  tooltip="Chance to Fill" 
                  contentClassName="text-[0.75rem]" 
                  offset={5} 
                >
                <Tab value={'ctf'}>CTF</Tab>
              </Tooltip>
                  
              </TabsHeader>
            </Tabs>
          </div>

          <div className="lg:h-[calc(100vh-34rem)] h-[70vw]">
            {chartType === 'ctf' && (
              <CTFChart
                bond={bond}
                quantity={quantityNumber}
                priceType={priceType}
              />
            )}

            {chartType === 'time' && (
              <Chart
                bond={bond}
                atsIndicator={atsIndicator}
                quantity={quantityNumber}
                trades={trades}
                xAxisKey={xAxisKey}
                priceType={priceType} />
            )}
          </div>
        </div>
        <div className="w-full lg:col-start-1 lg:overflow-y-auto lg:row-start-1 lg:row-end-3">
          <RecentTrades trades={trades} priceType={priceType} />
        </div>
      </div>
    </div>
  );
}

export default Bond;
