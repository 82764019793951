import { PriceType } from "@/types/types";

export enum AtsIndicator {
  N = 'N'
};

export enum Side {
  bid = 'bid',
  dealer = 'dealer',
  offer = 'offer'
};

export enum AlertTargetType {
  price =  'price',
  ytm =  'ytm', // yield to maturity
  gspread =  'gspread' 
}

export type InferenceRequestItem = {
  ats_indicator: AtsIndicator;
  figi: string;
  quantity: number;
  side: string;
  subscribe?: boolean;
  timestamp?: string[];
  rfq_label: PriceType;
  unsubscribe?: boolean;
};

export type ApiRequest = {
  reset?: boolean;
  trade?: {
    figi: string;
    index?: number[];
    subscribe?: boolean;
    unsubscribe?: boolean;
  }[]
  inference?: InferenceRequestItem[]
};

export type Inference = {
  ats_indicator: AtsIndicator;
  date: Date;
  figi: string;
  price?: number[];
  gspread?: number[];
  ytm?: number[];
  quantity: number;
  side: Side;
}

export type OldInferenceResponse = {
  ats_indicator: AtsIndicator;
  bid: number[];
  date: string;
  dealer: number[];
  figi: string;
  offer: number[];
  quantity: number;
};

export type NewInferenceResponse = {
  ats_indicator: AtsIndicator;
  side: Side;
  date: string;
  figi: string;
  quantity: number;
  gspread?: number[];
  ytm?: number[];
  price?: number[];
};

export type InferenceResponse = OldInferenceResponse | NewInferenceResponse;

type TradeBase = {
  entry: number,
  figi: string,
  side: 'B' | 'D' | 'S';
  quantity: number;
  price: number;
  inferred_price: number[];
  gspread: number;
  inferred_gspread: number[];
  ytm: number;
  inferred_ytm: number[];
}

export type Trade = TradeBase & {
  report_date: Date;
  execution_date: Date;
};

export type TradeResponse = TradeBase & {
  report_date: string;
  execution_date: string;
};

type Deactivated = {
  message: 'deactivated';
}

type UnrecognizedFigi = {
  message: 'unrecognized figi';
}

export const isMessageResponse = (response: ApiResponse): response is (Deactivated | UnrecognizedFigi) =>
  response.hasOwnProperty('message');

type Data = {
  start: number;
  trade?: TradeResponse[];
  inference?: InferenceResponse[];
}

export type ApiResponse = Deactivated | UnrecognizedFigi | Data;
