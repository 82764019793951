import TableListBoxV2Base from "@/app/components/listbox/tableListBoxV2/tableListBoxV2Base"
import { AlertTargetType } from "@/app/data/api";
import { AlertTableItem } from "../alertsTable";

export const AlertTargetTypeDropdown = ({
  alert,
  onChange,
}: {
  alert: AlertTableItem['alert'],
  onChange: (data: { id: string, targetType: AlertTargetType }) => void;
}) => {
  const targetTypeOptions = Object.values(AlertTargetType);

  return (
    <TableListBoxV2Base
      value={alert.targetType}
      options={targetTypeOptions}
      selectValue={v => {
        onChange({ id: alert.id, targetType: v })
      }}
    />
  )
}