import { PortfolioColumn, PortfolioColumnTitle } from "./portfolio.constants";

export const getColumnTitle = (previousString?: string) => (column: PortfolioColumn) => {
  const title = PortfolioColumnTitle[column];

  if (typeof title !== 'string') {
    return title;
  }

  return title.replace('{prevPeriod}', previousString || '')
};