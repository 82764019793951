import ActionModal from "@/app/components/modal/actionModal"
import { DataItem, ProbabilityModal } from "@/app/components/modal/probabilityModal"
import { PercentilesButton } from "@/app/components/table/components/percentilesButton"
import Table, { Column } from "@/app/components/table/table"
import { Side } from "@/app/data/api"
import { PROBABILITY_FORMAT } from "@/constants"
import { DataFields } from "@/hooks/data/useSimpleInferenceData"
import { useUiMode } from "@/hooks/useUiMode"
import { PriceType } from "@/types/types"
import { ProbabilityCalculationItem } from "@/utils/inference/calculateMaxProbability"
import { ProfitCalculationItem } from "@/utils/inference/calculateMaxProfit"
import { formatPrice } from "@/utils/number.utils"
import clsx from "clsx"
import { ReactNode, useCallback, useMemo, useState } from "react"
import { numericFormatter } from "react-number-format"

export type QuantilesData = {
  offerQuantiles: number[];
  bidQuantiles: number[];
}

type MaxProfitProps = {
  type: 'profit'
  data: ProfitCalculationItem[] | undefined;
}

type MaxProbabilityProps = {
  type: 'probability'
  data: ProbabilityCalculationItem[] | undefined
}

type QuantilesProps = {
  type: 'quantiles'
  data: QuantilesData | undefined
  inferenceData: { [key in Side]: DataFields }
}

type Props = MaxProfitProps | MaxProbabilityProps | QuantilesProps

export const RunMaxProbabilityResultPreview = (props: Props) => {
  const { type, data } = props
  const [modalOpen, setModalOpen] = useState(false)
  const { isIgUiMode } = useUiMode();

  let d: DataItem[] = [];

  if (type === 'quantiles') {
    const { inferenceData } = props
    d = [
      {
        title: 'Bid G-Spd',
        quantiles: inferenceData.bid.gspread.quantiles,
        side: Side.bid,
        priceType: PriceType.GSpread,
      },
      {
        title: 'Offer G-Spd',
        quantiles: inferenceData.offer.gspread.quantiles,
        side: Side.offer,
        priceType: PriceType.GSpread,
      },
      {
        title: 'Bid YTM',
        quantiles: inferenceData.bid.ytm.quantiles,
        side: Side.bid,
        priceType: PriceType.Ytm,
      },
      {
        title: 'Offer YTM',
        quantiles: inferenceData.offer.ytm.quantiles,
        side: Side.offer,
        priceType: PriceType.Ytm,
      },
      {
        title: 'Bid Px',
        quantiles: inferenceData.bid.price.quantiles,
        side: Side.bid,
        priceType: PriceType.Price,
      },
      {
        title: 'Offer Px',
        quantiles: inferenceData.offer.price.quantiles,
        side: Side.offer,
        priceType: PriceType.Price,
      },
    ]
  }

  return (
    <>
      <PercentilesButton onClick={() => setModalOpen(true)} />

      {modalOpen && (type === 'profit' || type === 'probability') && (
        <ActionModal
          show={modalOpen}
          hideCancel
          size="content"
          closeOnOutsideClick
          onClose={() => setModalOpen(false)}
          body={
            <ProbabilityCalcTable data={data} type={type} />
          }
        />
      )}

      {modalOpen && type === 'quantiles' && (
        <ProbabilityModal
          modalOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          data={d}
        />
      )}
    </>
  )
}

const tdCss = () => 'px-[0.3125rem] first:rounded-l-[0.625rem] last:rounded-r-[0.625rem] group-hover:bg-[#5D5F9D] group-hover:text-[#FBFBFD]';

const header = (content: ReactNode) => (
  <div className="border-[#5D5F9D] border-b-[0.0625rem] h-full px-[0.3125rem] w-full">
    <div className={'flex flex-col h-full items-start justify-center px-[0.625rem] py-[0.625rem] rounded-t-[0.625rem] text-[0.875rem] text-[#C9CADE] text-start font-bold'}>
      {content}
    </div>
  </div>
)

const cell = (content: ReactNode) => (
  <div className={'flex flex-col h-full justify-center px-[0.625rem] py-[1rem] text-[0.875rem]'}>
    {content}
  </div>
)

const ProbabilityCalcTable = ({ data = [], type }: {
  data: ProbabilityCalculationItem[] | ProfitCalculationItem[] | undefined
  type: 'probability' | 'profit'
}) => {
  const { isIgUiMode } = useUiMode();
  const isProfit = type === 'profit';

  function formatProbabiilty(probability: number) {
    return probability < 0.05 ? '< 5%' : numericFormatter(`${probability * 100}`, PROBABILITY_FORMAT)
  }

  const columns: Column<ProbabilityCalculationItem | ProfitCalculationItem>[] = useMemo(() => {

    const columns: Column<ProbabilityCalculationItem | ProfitCalculationItem>[] = [
      {
        id: 'bid',
        Cell: (d) => cell(d.bid),
        Header: () => header('Bid'),
        tdCss
      },
      {
        id: 'offer',
        Cell: (d) => cell(d.offer),
        Header: () => header('Offer'),
        tdCss
      },
      {
        id: 'bidProbability',
        Cell: (d) => cell(formatProbabiilty(d.bidProbability)),
        Header: () => header('Bid Trade Probability'),
        tdCss
      },
      {
        id: 'offerProbability',
        Cell: (d) => cell(formatProbabiilty(d.offerProbability)),
        Header: () => header('Offer Trade Probability'),
        tdCss
      },
      {
        id: 'tradeProbability',
        Cell: (d) => cell(formatProbabiilty(d.maxProbability)),
        Header: () => header('Joint Trade Probability'),
        tdCss
      }
    ]

    if (isProfit) {
      columns.push({
        id: 'spread',
        Cell: (d) => cell(d.offer - d.bid),
        Header: () => header('Spread'),
        tdCss
      }, {
        id: 'profit',
        Cell: (d) => cell(
          formatPrice((d as ProfitCalculationItem).profit, isIgUiMode ? PriceType.GSpread : PriceType.Price)
        ),
        Header: () => header('Expected Profit'),
        tdCss
      })
    }

    return columns
  }, [isIgUiMode, isProfit]);

  const { maxProbability, maxProfit } = useMemo(() => {
    if (isProfitlCalculationArray(data)) {
      return {
        maxProbability: 0,
        maxProfit: Math.max(...data.map(d => d.profit), 0)
      }
    } else {
      return {
        maxProbability: Math.max(...data.map(d => d.maxProbability), 0),
        maxProfit: 0
      }
    }
  }, [data])

  const trCss = useCallback((item: ProbabilityCalculationItem | ProfitCalculationItem) => {
    const isHighlighted = isProfitCalculationItem(item)
      ? item.profit === maxProfit
      : item.maxProbability === maxProbability

    return clsx(
      'rounded-[0.625rem] hover:shadow-[-0.375rem_-0.375rem_1.875rem_0_#615EFF66,0.625rem_0.625rem_1.875rem_0_#07011F59]', {
      'bg-gradient-to-r from-[#5D5F9D77]': isHighlighted,
    })
  }, [maxProbability, maxProfit]);

  return (
    <div className={clsx(' max-w-full', {
      'w-[750px]': isProfit,
      'w-[550px]': !isProfit
    })}>
      <Table
        columns={columns}
        generateItemKey={b => `${b.bid}-${b.offer}`}
        items={data}
        tableCss='w-full'
        tableName='probability-calc-table'
        theadCss='bg-[#333557] sticky top-0 z-[2]'
        trCss={trCss}
        scrollerCss="!max-h-[60vh]"
        defaultPageSize={10}
        noDataMessage="Loading..."
      />
    </div>
  )
}

const isProfitCalculationItem = (item: ProbabilityCalculationItem | ProfitCalculationItem): item is ProfitCalculationItem => {
  return (item as ProfitCalculationItem)?.profit !== undefined
}

const isProfitlCalculationArray = (data: ProbabilityCalculationItem[] | ProfitCalculationItem[]): data is ProfitCalculationItem[] => {
  return isProfitCalculationItem(data?.[0])
}
