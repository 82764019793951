import { useMemo } from "react";
import { GetInference } from "../../data/useInferences";
import { AlertTableItem } from "../alertsTable";
import { AlertTargetType, AtsIndicator, Side } from "../../data/api";
import { formatPercentage, getQuantileIndexByPctg, isAlertDisabled, isTargetAlert } from "../alert.utils";
import { getAsymptoticSpectrumBackground, getPercentSpectrumBackground } from "../../components/spectrum";
import { AlertInferenceData, ConditionData, DataFields } from "../alert.types";
import { isEmpty } from "lodash";
import { AlertTargetTypeToPriceType, DEFAULT_VOLATILITY_CONDITION } from "../alert.constants";
import { PriceType } from "@/types/types";

// calculates different data based on inference results - see DataFields
export const useAlertInferenceData = (alerts: AlertTableItem[], getInference: GetInference) => {
  const data = useMemo(() => {
    return alerts
      .reduce((result, _a) => {
        const { alert: a, bond: b } = _a;

        const data = isTargetAlert(a)
          ? getTargetAlertInferenceData(_a, getInference)
          : getVolatilityAlertInferenceData(_a, getInference)


        result.alert[a.id] = data;

        // store data by cusip
        result.cusip[b.cusip] = {
          ...result.cusip[b.cusip],
          [a.side]: data,
        };

        return result;
      }, { alert: {}, cusip: {} } as AlertInferenceData)

  }, [alerts, getInference]);


  return data;
}

function getVolatilityAlertInferenceData(alert: AlertTableItem, getInference: GetInference): DataFields {
  const { alert: a, bond: b } = alert;
  const rfqLabel = AlertTargetTypeToPriceType[a.targetType];
  const i = getInference(b.figi, AtsIndicator.N, a.size, a.side, rfqLabel);
  const iPrev = getInference(b.figi, AtsIndicator.N, a.size, a.side, rfqLabel, true);

  const quantiles = i?.[a.targetType] || [];
  const prevQuantiles = iPrev?.[a.targetType] || [];
  let targetReached = false;
  let isGapUp = false;
  let isGapDown = false;
  const conditionData: ConditionData = {
    todayMin: undefined,
    todayMax: undefined,
    prevMin: undefined,
    prevMax: undefined,
  }

  if (!isAlertDisabled(alert.alert) && !isEmpty(quantiles) && !isEmpty(prevQuantiles)) {
    const [max, min] = alert.alert.condition || DEFAULT_VOLATILITY_CONDITION;
    const maxIdx = getQuantileIndexByPctg(max);
    const minIdx = getQuantileIndexByPctg(min);

    conditionData.todayMin = quantiles[minIdx];
    conditionData.todayMax = quantiles[maxIdx];
    conditionData.prevMin = prevQuantiles[minIdx];
    conditionData.prevMax = prevQuantiles[maxIdx];

    const { todayMin, todayMax, prevMin, prevMax } = conditionData;


    isGapUp = todayMin > prevMax
    isGapDown = todayMax < prevMin;
    // isGapDown = true;
    // isGapUp = true;
    targetReached = isGapUp || isGapDown;
  }
  

  return {
    targetReached,
    isGapUp,
    isGapDown,
    conditionData,
  } as DataFields
}


// getTargetAlertInferenceData
function getTargetAlertInferenceData(alert: AlertTableItem, getInference: GetInference): DataFields {
  const { alert: a, bond: b } = alert;


  // const s = a.side === Side.bid ? Side.offer : Side.bid; // alert requires opposite inference side
  const s = a.side;

  const rfqLabel = AlertTargetTypeToPriceType[a.targetType];
  const inference = getInference(b.figi, AtsIndicator.N, a.size, s, rfqLabel);

  //find percentile based on target value
  let quantiles = inference?.[a.targetType] || [];

  // find index of quantile which is bigger than target value and less than next target value
  let currentPctg = getPercentileFromTarget(quantiles, a.targetValue, a.side, a.targetType);
  const currentPctgRange = formatPercentage(currentPctg);

  // calculate current target value
  const quantileIndex = Math.max(0, Math.floor((s === Side.offer ? 100 - a.minProbabilityPctg : a.minProbabilityPctg) / 5) - 1);
  // let quantileIndex: number;
  // if (a.targetType === AlertTargetType.price) {
  //   quantileIndex = Math.max(0, Math.floor((s === Side.offer ? 100 - a.minProbabilityPctg : a.minProbabilityPctg) / 5) - 1);
  // } else {
  //   quantileIndex = Math.max(0, Math.floor((s === Side.bid ? 100 - a.minProbabilityPctg : a.minProbabilityPctg) / 5) - 1);
  // }
  const current = quantiles[quantileIndex];
  const distance = getDistance(current, a);

  const targetReached = isTargetReached(currentPctg, a);
  const pctgBgColor = getPercentSpectrumBackground((currentPctg || 0) / 100, 'darkTogreen');

  const distanceBgColor = getDistanceBgColor(distance);

  const data = {
    // colors
    distanceBgColor,
    pctgBgColor,

    // data
    current,
    currentPctg,
    currentPctgRange,
    currentStr: current !== undefined ? current.toFixed(3) : '-',
    distance,
    targetReached,
  } as DataFields;

  return data;
}



function isTargetReached(currentPctg: number | undefined, alert: AlertTableItem['alert']) {
  if (currentPctg === undefined || isAlertDisabled(alert)) {
    return false;
  }


  return currentPctg >= alert.minProbabilityPctg;
  // if (alert.targetType === AlertTargetType.price) {
  //   return currentPctg >= alert.minProbabilityPctg;
  // } else {
  //   return currentPctg <= alert.minProbabilityPctg;
  // }

}

function getDistance(current: number | undefined, alert: AlertTableItem['alert']) {
  if (current === undefined || alert.targetValue === undefined || alert.targetValue === null) {
    return undefined;
  }

  const target = alert.targetValue || 0;
  let distance: number;

  if (alert.targetType === AlertTargetType.price) {
    distance = alert.side === Side.offer
      ? current - target
      : target - current;
  } else {
    distance = alert.side === Side.offer
      ? target - current
      : current - target;
  }


  return Math.round(distance * 1000) / 1000;
}

function getPercentileFromTarget(quantiles: number[], target: number | undefined | null, sdie: Side, priceType: AlertTargetType): number | undefined {
  // const q = sdie === sdie.offer ? [...quantiles].reverse() : quantiles;
  const q = quantiles;

  if (isEmpty(q) || typeof target !== 'number') {
    return undefined
  }

  const index = q.findIndex((n, idx) => {
    const next = q[idx + 1];
    return n <= target && (next === undefined || next > target);
  });

  let currentPctg: number | undefined = index === -1 ? 0 : (index + 1) * 5;


  if (index !== -1 && index !== q.length - 1) {
    // find exact percentile based on target value
    const lower = q[index];
    const upper = q[index + 1];
    const range = upper - lower;
    const pctg = (target - lower) / range * 5;

    currentPctg = currentPctg === undefined ? pctg : Math.floor(currentPctg + pctg);
  }

  if (priceType === AlertTargetType.price) {
    return sdie === Side.offer ? 100 - currentPctg : currentPctg;
  } else {
    return sdie === Side.bid ? 100 - currentPctg : currentPctg;;
  }
}

function getDistanceBgColor(distance: number | undefined) {
  const neutralStyles = 'bg-[#484A7A]';
  const minRequiredDistance = 1;

  if (typeof distance === 'undefined') {
    return neutralStyles;
  }

  if (distance >= 0) {
    // target reached - gold color
    return 'bg-[#FFD700] text-[#484a7a] group-hover:text-[white]';
  }

  if (distance < -1 * minRequiredDistance) {
    // neutral color - too far from target
    return neutralStyles;
  }


  const absDistance = Math.abs(distance);
  const pctg = (minRequiredDistance - absDistance) / minRequiredDistance;
  return getAsymptoticSpectrumBackground(pctg, 'darkTogreen')
}