import { XMarkIcon } from '@heroicons/react/20/solid';
import { AlertTableItem } from "../alertsTable";
import { useOpenPage } from '@hooks/useOpenPage';
import { AlertItem } from '../../data/alerts';
import { AlertInferenceData } from '../alert.types';
import { Row, Col } from '@components/itemCard';
import { isTargetAlert } from '../alert.utils';
import { TargetAlertDetails } from './components/targetAlertDetails';
import { VolatilityAlertDetails } from './components/volatilityAlertDetails';
import { formatAmountOutstanding } from '@/utils/number.utils';

export type AlertCardProps = {
  alertItem: AlertTableItem,
  data: AlertInferenceData;
  canChangeSide: (alert: AlertTableItem['alert']) => boolean,
  onChange: (alert: Partial<AlertItem> & { id: string }) => void
  onDelete: (alert: AlertTableItem) => void,
}

export const AlertCard = (props: AlertCardProps) => {
  const {
    alertItem,
    onDelete,
  } = props

  const openPage = useOpenPage();
  const { bond, alert } = alertItem;


  const DetailsComponent = isTargetAlert(alert) ? TargetAlertDetails : VolatilityAlertDetails

  return (
    <div
      className={"border-transparent bg-[#1F2034] border-2 flex flex-col gap-[0.75rem] items-center max-w-[17.1875rem] min-w-[17.1875rem] pb-[0.625rem] pt-[1.875rem] px-[0.625rem] rounded-[0.625rem] w-[17.1875rem] text-[0.875rem] "}
    >
      <div className="flex flex-row items-center justify-end mb-[-0.875rem] mr-[-1.5rem] mt-[-1.875rem] w-full">
        <button
          className="bg-transparent h-[1.5rem] flex flex-col items-center justify-center rounded-full w-[1.5rem]"
          onClick={e => {
            e.stopPropagation();
            onDelete(alertItem);
          }}
        >
          <XMarkIcon className="h-[1rem] text-[#4384C8]" />
        </button>
      </div>

      <Row>
        <Col label="Ticker" value={bond.ticker} />
        <Col label="Coupon" value={bond.coupon.toFixed(3)} end />
      </Row>
      <Row>
        <Col label="Maturity" value={bond.maturity} />
        <Col label="Series" value={bond.series || '-'} end />
      </Row>
      <Row>
        <Col label="CUSIP" value={bond.cusip} onClick={() => openPage.bond(bond)} />
        <Col label="S&P" value={bond.rating} end />
      </Row>
      <Row>
        <Col label="Outstanding" value={formatAmountOutstanding(bond.amountOutstanding)} />
      </Row>
      <Row>
        <Col label="Name" value={bond.issuer} valueClassName="overflow-x-auto whitespace-nowrap w-[15.9375rem]" />
      </Row>

      <DetailsComponent {...props} />
    </div>
  )
};



