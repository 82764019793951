import { NUMBER_FORMAT_MAP, ONE_MILLION, ONE_THOUSAND } from "@/constants";
import { PriceType } from "@/types/types";
import { numericFormatter } from "react-number-format";

export const toFixed = (num: number | string, count: number = 2) => {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }

  return parseFloat(num.toFixed(count));
};

export const priceOrString = (price: number | undefined) => {
  const v = price ?? '';
  return typeof v === 'string' ? v : toFixed(v, 3);
}

export const formatPrice = (price: number | undefined, priceType = PriceType.Price): string => {
  const format = NUMBER_FORMAT_MAP[priceType];

  if (typeof price !== 'number') {
    return '';
  }


  return numericFormatter(`${price}`, format)
}

export const formatAmountOutstanding = (value: number | undefined): string => {
  if (typeof value !== 'number') {
    return '-';
  }

  // MM -> means millions
  const suffix = value === 0 
    ? '' 
    : value >= ONE_MILLION 
      ? ' MM' 
      : ' K';
  const _value = value >= ONE_MILLION ? value / ONE_MILLION : value / ONE_THOUSAND;

  return numericFormatter(`${_value}`, {
    thousandSeparator: ',',
    decimalScale: 0,
    fixedDecimalScale: true,
    suffix,
  });
}

export const numberOrUndefined = (value: number) => {
  return  isNaN(value) ? undefined : value;
}

export const roundToEighth = (value: number) => Math.round(value * 8) / 8;

export const roundToDecimals = (value: number, decimals: number) => {
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
}