import { useCallback, useContext, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useInferences, { InferenceRequest } from '@data/useInferences';
import { AtsIndicator, Bond, DataContext, Side } from '@data/dataProvider';
import Loading from '../loading';
import IssuerTable from './issuerTable';
import GlobalPercentileListbox from '../components/listbox/globalPercentileListbox';
import ClearSelectedBonds from '../components/clearSelectedBonds';
import AddToPortfolio from '../portfolio/addToPortfolio';
import AddToRun from '../run/addToRun';
import { useOpenPage } from '../../hooks/useOpenPage';
import AddAlertButton from '../alert/addAlertButton';
import { QueryParam } from '@/constants';
import { Filters } from '../components/filters/filters';
import { usePreviousMs } from '../components/filters/hooks/useFilters';
import { useSimpleInferenceData } from '@/hooks/data/useSimpleInferenceData';
import { useFilteredItems } from '../components/filters/hooks/useFilteredItems';
import { BondCard } from './bondCard';
import { isEmpty } from 'lodash';
import { ColumnConfigMap, ColumnsOrderConfig, NO_BONDS_FOR_FILTERS } from './issuer.constants';
import { useGetInferenceRfqLabelsFromColumns } from '@/hooks/data/useGetInferenceRfqLabelsFromColumns';
import { useBondsInferenceRequests } from '@/hooks/data/useBondsInferenceRequests';
import { PriceType } from '@/types/types';
import { RoundPriceToggle } from '../components/roundPriceToggle';

const DEFAULT_BONDS: Bond[] = [];

const Issuer = () => {
  const { ticker } = useParams();
  const [searchParams] = useSearchParams();
  const { getIssuer, getIssuerBonds } = useContext(DataContext);
  const openPage = useOpenPage();
  const [quantity] = useState<number>(1000000);

  const bonds = useMemo(() => getIssuerBonds && ticker ? getIssuerBonds(ticker).sort((a, b) => (new Date(a.maturity)).getTime() - (new Date(b.maturity)).getTime()) : null, [getIssuerBonds, ticker]);
  const issuer = useMemo(() => getIssuer && ticker ? getIssuer(ticker) : null, [getIssuer, ticker]);

  //  prepare bond request data
  const rfqLabelMap = useGetInferenceRfqLabelsFromColumns(ColumnsOrderConfig, ColumnConfigMap);
  const getQuantity = useCallback(() => quantity, [quantity]);
  const bondRequests = useBondsInferenceRequests({
    bonds: bonds || DEFAULT_BONDS,
    rfqLabelMap,
    getQuantity,
  });

  // prepare inference request
  const { previousMs } = usePreviousMs();
  const [getInferenceFull] = useInferences(bondRequests, previousMs || undefined);
  const getInference = useCallback((figi: string, side: Side, rfqLabel: PriceType, previous: boolean = false) => {
    return getInferenceFull(figi, AtsIndicator.N, quantity, side, rfqLabel, previous);
  }, [getInferenceFull, quantity]);
  const roundPrice = searchParams.get(QueryParam.RoundPrice) === 'true';
  const inferenceResult = useSimpleInferenceData({
    items: bonds || DEFAULT_BONDS,
    getInference,
    roundPrice,
  });

  // filter bonds
  const filteredBonds = useFilteredItems(bonds || DEFAULT_BONDS, inferenceResult);

  if (!ticker || !ticker.length) {
    openPage.search({ replace: true });
  }
  if (!issuer || !bonds) {
    return <Loading className="mt-[2.5rem]" />;
  }
  return (
    <>
      <div className="flex flex-col gap-[0.625rem] items-start p-[0.625rem] w-full">
        <div className="flex flex-col items-start w-full">
          <div className="bg-[#333557] flex flex-row pl-[0.625rem] py-[0.625rem] rounded-t-[0.625rem] w-full sm:pl-[1.25rem] sm:py-[1.25rem]">
            <div className="gap-x-[0.625rem] grid grid-cols-[auto,auto,auto] grid-rows-[auto,auto]">
              <div className="leading-[0.75rem] row-start-1 text-[0.75rem] text-[#8183B3]">Ticker</div>
              <div className="row-start-2 text-[0.875rem] md:text-[1.5rem]">{issuer.ticker}</div>
              <div className="border-r border-[#5D5F9D] col-start-2 row-start-1 row-end-3"></div>
              <div className="col-start-3 leading-[0.75rem] row-start-1 text-[0.75rem] text-[#8183B3]">Name</div>
              <div className="col-start-3 row-start-2 text-[0.875rem] md:text-[1.5rem]">{issuer.issuer}</div>
            </div>
          </div>
          <div className="bg-[#0A0B11] px-[0.625rem] py-[0.625rem] rounded-b-[0.625rem] w-full sm:px-[1.25rem] sm:py-[1.25rem]">
            <div className="flex flex-row gap-[0.625rem] max-w-[40rem] w-full">
              <GlobalPercentileListbox multiline={true} side={Side.bid} />
              <GlobalPercentileListbox multiline={true} side={Side.dealer} />
              <GlobalPercentileListbox multiline={true} side={Side.offer} />
            </div>
          </div>
        </div>

        <div className='flex items-center justify-between w-full gap-[0.625rem]'>
          {/* Filters */}
          <Filters
            bonds={bonds}
            pageSizeParamName={QueryParam.IssuerPageSize}
          />
          <div className='ml-auto block lg:hidden'>
            <RoundPriceToggle />
          </div>
        </div>

        <div className="bg-[#333557] flex flex-col hidden rounded-[0.625rem] w-full lg:block">
          <div className="flex flex-col lg:h-[calc(100vh-20.5rem)] pb-[0.625rem] pt-[0.875rem] px-[0.625rem] w-full">
            <div className="flex flex-row gap-[0.625rem] items-center p-[0.625rem]">
              <AddToPortfolio />
              <AddToRun />
              <AddAlertButton />
              <ClearSelectedBonds />

              <div className='ml-auto'>
                <RoundPriceToggle />
              </div>
            </div>
            <div className="h-[calc(100vh-25.5rem)] hidden pb-[0.625rem] w-full lg:block">
              <IssuerTable
                bonds={filteredBonds}
                inferenceResult={inferenceResult}
                selectBond={bond => void openPage.bond(bond)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-[0.75rem] items-start overflow-x-auto px-[0.625rem] w-full lg:hidden">
        {filteredBonds.slice(0, 20).map(b => (
          <div key={b.figi} onClick={() => void openPage.bond(b)}>
            <BondCard bond={b} inferenceResult={inferenceResult} />
          </div>
        ))}
        {filteredBonds.length > 20 && (
          <div className="bg-[#333557] flex flex-row items-center justify-center max-w-[17.1875rem] min-w-[17.1875rem] px-[0.625rem] py-[1.5rem] rounded-[0.625rem] w-[17.1875rem]">
            <span>{`${filteredBonds.length - 20} more...`}</span>
          </div>
        )}
        {isEmpty(filteredBonds) && <div className="text-[#8183B3]">{NO_BONDS_FOR_FILTERS}</div>}
      </div>
    </>
  );
}

export default Issuer;
