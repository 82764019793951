import { useState } from "react"
import { FilterDrawer, FilterName } from "./filterDrawer"
import { SelectedFilters } from "./selectedFilters";
import { Bond } from "@data/bondIndex";
import { FaFilter } from "react-icons/fa6";
import clsx from "clsx";

export const Filters = ({ 
  activeFilters,
  bonds,
  className,
  pageSizeParamName,
}: { 
  activeFilters?: FilterName[];
  bonds: Bond[];
  className?: string;
  pageSizeParamName?: string;
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <>
      {/* Filter Section */}
      <div className={clsx('flex flex-row gap-[0.625rem] items-center', className)}>
        {/* FilterButton */}
        <button
          type="button"
          className="bg-[#5D5F9D] h-[2.25rem] p-[0.5625rem] rounded-full w-[2.25rem] min-w-[2.25rem] self-start flex justify-center items-center"
          onClick={() => setDrawerOpen(true)}
        >
          <FaFilter className="text-[0.875rem]" />
        </button>

        {/* SelectedFilters */}
        <SelectedFilters bonds={bonds} />
      </div>


      {/* FilterDrawer */}
      <FilterDrawer
        show={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        bonds={bonds}
        pageSizeParamName={pageSizeParamName}
        activeFilters={activeFilters}
      />
    </>
  )
}