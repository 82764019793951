import { ReactNode } from "react";
import LinkToggle from "../linkToggle";
import { Column, Sort } from "../table/table"
import { isEmpty } from "lodash";
import { CheckboxUI } from "../checkbox";
import { CheckboxSelectAll } from "../checkboxSelectAll";

export const getLinkColumnConfig = <T,>({
  cell,
  selectedItems,
  setSelectedItems,
  items,
  header,
  getKey = (b) => (b as any).figi,
}: {
  cell: (element: ReactNode, selected: boolean) => ReactNode;
  selectedItems: Set<string>;
  header: (element: ReactNode, sort: Sort) => ReactNode;
  setSelectedItems: (selectedItems: Set<string>) => void;
  getKey?: (b: T) => string;
  items: T[];
}) =>  {

  const column: Omit<Column<T>, 'printColumns'> = {
    id: 'link',
    printable: false,
    draggable: false,
    Cell: (b, {selected}) => cell(
      <LinkToggle
        aria-label={`Select`}
        linked={selectedItems.has(getKey(b))}
        onClick={() => { /* click handled for the entire cell */ }}
      />
      , selected
    ),
    Header: sort => header(
      <LinkToggle
        aria-label='Select all'
        linked={!isEmpty(items) && items.every(b => selectedItems.has(getKey(b)))}
        onClick={() => { /* click handled for entire header */ }}
        count={selectedItems.size}
      />
      , sort
    ),
    onCellClick: (e, item) => {
      e.stopPropagation();
      const newList = new Set(selectedItems);
      if (newList.has(getKey(item))) {
        newList.delete(getKey(item));
      } else {
        newList.add(getKey(item));
      }
      setSelectedItems(newList);
    },
    onHeaderClick: () => {
      const newList = new Set<string>();
      const every = items.every(b => selectedItems.has(getKey(b)));
      if (every) {
        newList.clear();
      } else {
        items.forEach(b => void newList.add(getKey(b)));
      }
      setSelectedItems(newList);
    },
  };

  return column
}


export const getCheckboxColumnConfig = <T,>({
  cell,
  selectedItems,
  setSelectedItems,
  items,
  header,
  getKey = (b) => (b as any).figi,
}: {
  cell: (element: ReactNode, selected: boolean) => ReactNode;
  selectedItems: Set<string>;
  header: (element: ReactNode, sort: Sort) => ReactNode;
  setSelectedItems: (selectedItems: Set<string>) => void;
  getKey?: (b: T) => string;
  items: T[];
}) =>  {

  const handleToggleHeader = (pageItems: T[]) => () => {
    const newList = new Set(selectedItems);
    const every = pageItems.every(b => selectedItems.has(getKey(b)));
    if (every) {
      pageItems.forEach(b => void newList.delete(getKey(b)));
    } else {
      pageItems.forEach(b => void newList.add(getKey(b)));
    }
    setSelectedItems(newList);
  }

  const handleToggleAll = () => {
    const newList = new Set<string>();
    const every = items.every(b => selectedItems.has(getKey(b)));
    if (every) {
      newList.clear();
    } else {
      items.forEach(b => void newList.add(getKey(b)));
    }
    setSelectedItems(newList);
  }

  const column: Omit<Column<T>, 'printColumns'> =  {
    draggable: false,
    id: 'checkbox',
    Cell: (b, {selected}) => cell(
      <CheckboxUI
        ariaLabel={`Select item`}
        checked={selectedItems.has(getKey(b))}
        onClick={() => { /* click handled for the entire cell */ }}
      />
      , selected
    ),
    Header: (sort, pageItems) => header(
      <CheckboxSelectAll
        itemsTotal={items.length}
        checked={!isEmpty(pageItems) && pageItems.every(b => selectedItems.has(getKey(b)))}
        checkedAll={!isEmpty(items) && items.every(b => selectedItems.has(getKey(b)))}
        onToggle={handleToggleHeader(pageItems)}
        onToggleAll={handleToggleAll}
      />
      , sort
    ),
    onCellClick: (e, bond) => {
      e.stopPropagation();
      const newList = new Set(selectedItems);
      if (newList.has(getKey(bond))) {
        newList.delete(getKey(bond));
      } else {
        newList.add(getKey(bond));
      }
      setSelectedItems(newList);
    },
  }

  return column;
}