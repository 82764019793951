import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CheckIcon, EllipsisVerticalIcon, MagnifyingGlassIcon, PlusIcon, SquaresPlusIcon } from '@heroicons/react/20/solid';
import { AtsIndicator, Bond, DataContext, Side } from '../data/dataProvider';
import useInferences, { InferenceRequest } from '../data/useInferences';
import { useCreatePortfolio, useDeletePortfolio, useEditPortfolio, useRemoveFromPortfolio } from '../data/portfolios';
import Loading from '../loading';
import GlobalPercentileListbox from '../components/listbox/globalPercentileListbox';
import StyledMenu from '../components/styledMenu';
import ListBox from "../components/listbox/listbox";
import ActionModal from '../components/modal/actionModal';
import ActionWithInputModal from '../components/modal/actionWithInputModal';
import PortfolioCard from './portfolioCard';
import PortfolioTable from './portfolioTable';
import { useOpenPage } from '../../hooks/useOpenPage';
import { useGetPortfolioListQuery, useGetPortfolioQuery } from '@/store/api/portfolio.endpoints';
import { isEmpty, isEqual } from 'lodash';
import { DownloadCsvModal } from '../components/modal/downloadCsvModal';
import { ColumnConfigMap, ColumnsOrderConfig, NO_POSITIONS_FOR_FILTERS, PortfolioColumn, PortfolioColumnPrintTitle } from './portfolio.constants';
import { useTableToCsv } from '../components/table/hooks/useTableToCsv';
import { useSelector } from 'react-redux';
import { selectHasCusipAccess } from '@/store/slices/auth.slice';
import { useSimpleInferenceData } from '@hooks/data/useSimpleInferenceData';
import { QueryParam } from '@/constants';
import { usePreviousMs } from '@components/filters/hooks/useFilters';
import { Filters } from '../components/filters/filters';
import { useFilteredItems } from '../components/filters/hooks/useFilteredItems';
import { useGetInferenceRfqLabelsFromColumns } from '@/hooks/data/useGetInferenceRfqLabelsFromColumns';
import { useBondsInferenceRequests } from '@/hooks/data/useBondsInferenceRequests';
import { PriceType } from '@/types/types';
import { RoundPriceToggle } from '../components/roundPriceToggle';


export type Position = {
  figi: string;
  position: number;
}

const Portfolio = () => {

  const { portfolio: portfolioId } = useParams();
  const [searchParams] = useSearchParams();
  const { getBond, selectedBonds } = useContext(DataContext);
  const openPage = useOpenPage();
  const hasCusipAccess = useSelector(selectHasCusipAccess);
  
  const { currentData: unsortedPortfolios, isLoading: isLoadingPortfolios, isFetching: isFetchingPortfolios } = useGetPortfolioListQuery();
  const portfolioToName = useMemo(() =>
    (unsortedPortfolios || []).reduce((a: { [key: string]: string }, c) => {
      a[c.id] = JSON.parse(c.metadata)['name'];
      return a;
    }, {})
  , [unsortedPortfolios]);
  const portfolios = useMemo(() => {
    if (unsortedPortfolios) {
      const sorted = [...unsortedPortfolios];
      sorted.sort((a, b) => portfolioToName[a.id].localeCompare(portfolioToName[b.id]));
      return sorted;
    } else {
      return unsortedPortfolios;
    }
  }, [portfolioToName, unsortedPortfolios]);

  useEffect(() => {
    if (isFetchingPortfolios) {
      return;
    }

    if (portfolios && portfolios.length && (!portfolioId || !(portfolioId in portfolioToName))) {
      // if no portfolio or the portfolio doesn't exist then go to another (if any)
      const differentId = portfolios.map(p => p.id).find(pid => pid !== portfolioId);
      openPage.portfolio(differentId, { replace: true });
    } else if (portfolioId && portfolioId.length && portfolios && !portfolios.length) {
      // if no portfolios and portfolioId exists then navigate to portfolio
      openPage.portfolios({ replace: true });
    }
  }, [openPage, portfolioId, portfolios, portfolioToName, isFetchingPortfolios]);


  const pId = portfolioId && portfolioId in portfolioToName ? portfolioId : undefined;
  const { currentData: portfolio, isFetching: isFetchingPortfolio  } = useGetPortfolioQuery(pId as string, {
    skip: !pId
  })

  const positions = useMemo(() =>
    (portfolio ? portfolio.value.positions : []).map(p => {
      const bond = getBond ? getBond(p.figi) : null;
      return bond ? {...bond, ...p} : null;
    }).filter((v): v is (Position & Bond) => !!v)
    , [getBond, portfolio]);
   
  const figiToPosition = useMemo(() => positions.reduce((a: { [key: string]: Position & Bond }, c) => { a[c.figi] = c; return a; }, {}), [positions]);


  //  prepare bond request data
  const rfqLabelMap = useGetInferenceRfqLabelsFromColumns(ColumnsOrderConfig, ColumnConfigMap);
  const getQuantity = useCallback((p: Position) => p.position, []);
  const bondRequests = useBondsInferenceRequests({
    bonds: positions,
    rfqLabelMap,
    getQuantity,
  });

  // prepare inference request
  const { previousMs } = usePreviousMs();
  const [getInferenceFull] = useInferences(bondRequests, previousMs || undefined);
  const getInference = useMemo(() => (figi: string, side: Side, rfqLabel: PriceType, previous: boolean = false) => getInferenceFull(figi, AtsIndicator.N, figiToPosition[figi].position, side, rfqLabel, previous), [figiToPosition, getInferenceFull]);
  const roundPrice = searchParams.get(QueryParam.RoundPrice) === 'true';
  const inferenceResult = useSimpleInferenceData({
    items: positions,
    getInference,
    roundPrice,
  })

  // filter positions
  const filteredPositions =  useFilteredItems(positions, inferenceResult);

  // create
  const createPortfolio = useCreatePortfolio();
  const [createName, setCreateName] = useState<string>('');
  const [showCreate, setShowCreate] = useState<boolean>(false);

  // edit
  const editPortfolio = useEditPortfolio();
  const [editName, setEditName] = useState<string>('');
  const [showEdit, setShowEdit] = useState<boolean>(false);

  // delete
  const deletePortfolioApi = useDeletePortfolio();
  const [showDelete, setShowDelete] = useState<boolean>(false);

  // remove
  const removeFromPortfolio = useRemoveFromPortfolio();
  const [removeFigiSet, setRemoveFigiSet] = useState<Set<string>>(new Set());
  const [showRemove, setShowRemove] = useState<boolean>(false);
  const remove = useMemo(() =>
    (figiSet: Set<string>) => {
      setRemoveFigiSet(figiSet);
      setShowRemove(true);
    }
  , [setRemoveFigiSet, setShowRemove]);

  // download
  const [showDownload, setShowDownload] = useState<boolean>(false);

  // linking
  const [linkedFigis, setLinkedFigis] = useState<Set<string>>(new Set());

  // table to csv
  const { tableRef, getCsvRows, handleCopyClick } = useTableToCsv<Position & Bond, PortfolioColumn>({
    getColumnTitle: (columnKey) => {
      if (!hasCusipAccess && columnKey === PortfolioColumn.Cusip) {
        return 'FIGI';
      }
      return PortfolioColumnPrintTitle[columnKey];
    },
    parseValue: ({ columnKey, value, item }) => {
      if (!hasCusipAccess && columnKey === PortfolioColumn.Cusip) {
        return item.figi;
      }
      return value
    }
  }, [portfolioId]);

  useEffect(() => {
    if (portfolioId) {
      setLinkedFigis(new Set());
    }
  }, [portfolioId, setLinkedFigis])

  async function handleCreatePortfolio() {
    const result = await createPortfolio(createName, selectedBonds)
    if (!result) {
      return false; // prevent modal from closing
    }
  }

  if (portfolios && !portfolios.length) {
    return (
      <>
        <div className="flex flex-row p-[0.625rem] w-full">
          <button
            className="bg-[#5D5F9D] flex flex-row gap-[0.625rem] items-center justify-center px-[1.25rem] py-[0.625rem] rounded-[0.625rem]"
            onClick={() => {
              setCreateName('');
              setShowCreate(true);
            }}
          >
            <span>Create Portfolio</span>
          </button>
        </div>
        <ActionWithInputModal
          action={handleCreatePortfolio}
          actionName='Create'
          disabled={!createName}
          label='New Portfolio Name'
          placeholder='Name'
          setShow={setShowCreate}
          setValue={setCreateName}
          show={showCreate}
          value={createName}
          title='Create Portfolio'
        />
      </>
    );
  }

  if (isLoadingPortfolios || !portfolioId || !portfolioId.length || !getBond) {
    return <Loading className="mt-[2.5rem]" />;
  }

  return (
    <div className="pt-[1.25rem] lg:px-[1.25rem]">
      <div className="flex flex-row gap-[0.375rem] items-center justify-between sm:gap-[0.625rem]">
        <div className="bg-[#333557] flex flex-row gap-[0.375rem] items-center justify-between max-w-[20rem] min-w-[8rem] pl-[0.625rem] pr-[0.375rem] py-[0.625rem] rounded-t-[0.625rem] sm:gap-[0.625rem] sm:w-[20rem] sm:pl-[1.25rem] sm:pr-[0.625rem]">
          <div className="font-medium text-[0.875rem] truncate">{portfolioToName[portfolioId]}</div>
          <StyledMenu
            ariaLabel='Portfolio Menu'
            Button={() => <EllipsisVerticalIcon className="h-[1rem] w-[1rem]" aria-hidden="true" />}
            buttonCss='rounded-full'
            menuItems={[
              {
                ariaLabel: 'Copy to Clipboard',
                Item: () => 'Copy to Clipboard',
                key: 'copy',
                onClick: () => handleCopyClick(),
              },
              {
                ariaLabel: 'Download',
                Item: () => 'Download',
                key: 'download',
                onClick: () => void setShowDownload(true)
              },
              {
                ariaLabel: 'Edit',
                Item: () => 'Edit',
                key: 'edit',
                onClick: () => {
                  setEditName(portfolioToName[portfolioId]);
                  setShowEdit(true);
                }
              },
              {
                ariaLabel: 'Delete',
                Item: () => 'Delete',
                key: 'delete',
                onClick: () => void setShowDelete(true)
              }
            ]}
          />
        </div>
        <div className="flex flex-row gap-[0.375rem] items-center justify-center sm:gap-[0.625rem]">
          <RoundPriceToggle />
          <ListBox
            button={() => <SquaresPlusIcon className="h-full w-full" />}
            buttonCss={() => 'bg-[#5D5F9D] h-[2.25rem] p-[0.625rem] rounded-full w-[2.25rem]'}
            divCss='w-[auto]'
            popperProps={{
              placement: 'bottom-end',
            }}
            option={(value, props) =>
              value === '__new__'
                ? <div className="flex flex-row items-center justify-between">
                    <div>Create New Portfolio</div>
                    <PlusIcon className="h-[1rem] w-[1rem]" />
                  </div>
                : <>
                    <span className={`block text-[0.875rem] truncate ${props.selected ? 'font-medium' : 'font-normal'}`}>
                      {portfolioToName[value]}
                    </span>
                    {props.selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
            }
            optionCss={() => 'min-w-[305px] bg-[#5D5F9D] border-t-[0.0625rem] border-[#8183B3] cursor-default pl-[2.5rem] pr-[1rem] py-[0.4375rem] relative select-none text-[0.875rem] text-[#FBFBFD] first:border-t-[0] hover:bg-[#484A7A]'}
            optionsCss='absolute max-h-[10rem] mt-[0.25rem] origin-top-right overflow-auto right-0 rounded-[0.625rem] w-[20rem] z-[3]'
            selectValue={value => {
              if (value === '__new__') {
                setCreateName('');
                setShowCreate(true);
              } else {
                openPage.portfolio(value);
              }
            }}
            value={portfolioId}
            values={['__new__', ...(portfolios || []).map(b => b.id)]}
          />
        </div>
      </div>
      <div className="bg-[#333557] rounded-tr-[0.625rem] lg:h-[calc(100vh-11.3rem)] lg:rounded-b-[0.625rem]">
        <div className="flex flex-col gap-[0.625rem] items-start py-[0.625rem] w-full">
          <div className="gap-[0.625rem] grid grid-rows-1 grid-cols-[8.5rem,8.5rem] px-[0.625rem]">
            <GlobalPercentileListbox multiline={false} side={Side.bid} />
            <GlobalPercentileListbox multiline={false} side={Side.offer} />
          </div>

          {/* Filters  */}
          <Filters 
            bonds={positions} 
            className='px-[0.625rem]' 
            pageSizeParamName={QueryParam.PortfolioPageSize}
          />

          {
            portfolio  && positions.length > 0 && (
              <div className="h-[calc(100vh-18.25rem)] hidden pb-[0.625rem] w-full lg:block">
                  <PortfolioTable
                    positions={filteredPositions}
                    linkedFigis={linkedFigis}
                    remove={remove}
                    selectBond={b => void openPage.bond(b)}
                    setLinkedFigis={setLinkedFigis}
                    tableRef={tableRef}
                    inferenceResult={inferenceResult}
                  />
                </div>
            )
          }
        </div>
        {
          isFetchingPortfolio
            ? <Loading className="py-[3rem] pt-[0.875rem]" />
            :
              !positions.length
                ? <div className="bg-[#333557] flex flex-col mx-[0.625rem] place-items-center py-[2rem] rounded-[0.625rem] text-[#DDDDE2] sm:py-[2.5rem] sm:text-[1.25rem]">
                    <MagnifyingGlassIcon className="h-[2rem] mb-[0.2rem] text-[#C9CADE] sm:h-[2.8rem]" />
                    <span>Start searching and add bonds</span>
                    <span>to this portfolio.</span>
                  </div>
                : (
                  <div className="flex flex-row gap-[0.75rem] items-start overflow-x-auto pb-[1rem] px-[0.625rem] w-full lg:hidden">
                    {filteredPositions.slice(0,20).map(p => (
                      <div key={p.figi} onClick={() => void openPage.bond(p)}>
                        <PortfolioCard
                          position={p}
                          remove={figi => void remove(new Set([figi]))}
                          inferenceResult={inferenceResult}
                        />
                      </div>
                    ))}
                    {filteredPositions.length > 20 && (
                      <div className="bg-[#1F2034] flex flex-row items-center justify-center max-w-[17.1875rem] min-w-[17.1875rem] px-[0.625rem] py-[1.5rem] rounded-[0.625rem] w-[17.1875rem]">
                        <span>{`${filteredPositions.length - 20} more...`}</span>
                      </div>
                    )}
                    {isEmpty(filteredPositions) && <div className="text-[#8183B3]">{NO_POSITIONS_FOR_FILTERS}</div>}
                  </div>
                )
        }
      </div>
      <ActionWithInputModal
        action={handleCreatePortfolio}
        actionName='Create'
        disabled={!createName}
        label='New Portfolio Name'
        placeholder='Name'
        setShow={setShowCreate}
        setValue={setCreateName}
        show={showCreate}
        value={createName}
        title='Create Portfolio'
      />
      <ActionWithInputModal
        action={() => editPortfolio(portfolioId, editName)}
        actionName='Edit'
        disabled={!editName}
        label='Edit Portfolio Name'
        placeholder='Name'
        setShow={setShowEdit}
        setValue={setEditName}
        show={showEdit}
        value={editName}
        title='Edit Portfolio'
      />
      <ActionModal
        action={() => deletePortfolioApi(portfolioId)}
        actionName='Delete'
        body={
          <div className="grid grid-cols-[auto,1fr,auto] grid-rows-1">
            <span>Delete</span>
            <span className="pl-[0.3125rem] text-[#FBFBFD] truncate">{portfolioToName[portfolioId]}</span>
            <span>?</span>
          </div>
        }
        onClose={() => setShowDelete(false)}
        show={showDelete}
        title='Delete Portfolio'
      />
      <ActionModal
        action={async () => {
          const result = await removeFromPortfolio(portfolioId, removeFigiSet);
          if (result === false) {
            return false; // prevent modal from closing
          }

          if (isEqual(removeFigiSet, linkedFigis)) {
            setLinkedFigis(new Set());
          }
        }}
        actionName='Remove'
        body={
          <div>
            Remove <span className="text-[#FBFBFD]">{removeFigiSet.size}</span> bond{removeFigiSet.size > 1 ? 's' : ''} from portfolio?
          </div>
        }
        onClose={() => setShowRemove(false)}
        show={showRemove}
      />

      {/* Download file Modal */}
      <DownloadCsvModal
        getRows={getCsvRows}
        name={portfolioToName[portfolioId]}
        show={showDownload}
        setShow={setShowDownload}
      />

    </div>
  );
}

export default Portfolio;
