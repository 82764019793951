import { ColumnConfigMapType, PriceType, UIMode } from "@/types/types";
import { useUiMode } from "../useUiMode";
import notUndefined from "@/utils/notUndefined";
import { Side } from "@/app/data/api";
import { useMemo } from "react";

// calculates inference rfq labels from columns for each side 
export const useGetInferenceRfqLabelsFromColumns = <T extends string>(
  columnsOrderConfig: Record<UIMode, T[]>,
  columnConfigMap: ColumnConfigMapType<T>
): Record<Side, PriceType[]> => {
  const { uiMode } = useUiMode();

  const rfqLabels = useMemo(() => {
    const columns = columnsOrderConfig[uiMode]; // get columns for current uiMode

    const configs = columns.map(column => columnConfigMap[column]).filter(notUndefined); // get column configs

    const rfqLabels = configs.reduce((acc, config) => {
      if (!config) return acc;

      acc[config.side].add(config.priceType);

      return acc;
    }, {
      [Side.bid]: new Set<PriceType>(),
      [Side.offer]: new Set<PriceType>(),
      [Side.dealer]: new Set<PriceType>(),
    })

    return Object.entries(rfqLabels).reduce((acc, [side, priceTypes]) => {
      acc[side as Side] = Array.from(priceTypes);
      return acc;
    }, {} as Record<Side, PriceType[]>)
  }, [uiMode, columnConfigMap, columnsOrderConfig])

  return rfqLabels;
}