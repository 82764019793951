import { PriceType, UIMode, UserPreferences } from "@/types/types";
import { NumericFormatProps } from "react-number-format";

export const ROUTE = {
  Alert: 'alert',
}

export const APP_ENV = process.env.REACT_APP_ENV; // dev | prod | stage
export const APP_RELEASE = process.env.REACT_APP_RELEASE;

export const SEARCH_BAR_ID = 'search';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const DEFAULT_PRICE_FORMAT: NumericFormatProps = {
  allowLeadingZeros: true,
  thousandSeparator: ",",
  decimalSeparator: '.',
  allowedDecimalSeparators: ['.', ','],
  decimalScale: 3,
  fixedDecimalScale: true,
  prefix: '$',
}

export const DEFAULT_YIELD_FORMAT: NumericFormatProps = {
  allowLeadingZeros: true,
  thousandSeparator: ",",
  decimalSeparator: '.',
  allowedDecimalSeparators: ['.', ','],
  decimalScale: 2,
  fixedDecimalScale: true,
  suffix: '%',
  prefix: '',
}

export const DEFAULT_SPREAD_FORMAT: NumericFormatProps = {
  allowLeadingZeros: false,
  fixedDecimalScale: true,
  decimalScale: 0,
  prefix: '',
}

export const NUMBER_FORMAT_MAP: Record<PriceType, NumericFormatProps> = {
  [PriceType.Price]: DEFAULT_PRICE_FORMAT,
  [PriceType.Ytm]: DEFAULT_YIELD_FORMAT,
  [PriceType.GSpread]: DEFAULT_SPREAD_FORMAT,
}

export const PROBABILITY_FORMAT: NumericFormatProps = {
  decimalScale: 2,
  suffix: '%'
}

export const TOKEN_REFRESH_WINDOW = {
  earliest: 90, // 90 seconds before expiration
  latest: 30 // 30 seconds before expiration
}

export const WARN_ABOUT_MANY_ITEMS_LIMIT = 100; // if user tries to add more than this number of alerts/portfolio/runs we show a warning

// TODO: Create alert channel instead and use broadcast events instead of channels
export enum BroadcastChannelName {
  AlertUpdate = 'alert-update',
  UserPreferencesUpdate = 'user-preferences-update',
}

export const LocalStorageKey = {
  AertSnoozeDefaultMS: 'dmm-alert-snooze-default-ms',
  UserPreferences: 'dmm-user-preferences',
}

export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;

export const QueryParam = {
  RoundPrice: 'roundPrice',

  // filter
  FilterTenorBuckets: 'f-tb',
  FilterTicker: 'f-t',
  FilterCoupon: 'f-c',
  FilterSeries: 'f-s',
  FilterRating: 'f-r',
  FilterBid: 'f-b',
  FilterOffer: 'f-o',
  FilterBidGspread: 'f-bg',
  FilterOfferGspread: 'f-og',
  FilterBidYtm: 'f-by',
  FilterOfferYtm: 'f-oy',

  // previous
  Previous: 'previous',

  // alert
  AlertId: 'alertId',
  AlertPageSize: 'a-pageSize',

  // portfolio
  PortfolioPageSize: 'p-pageSize',

  // run
  RunPageSize: 'r-pageSize',

  // issuer
  IssuerPageSize: 'i-pageSize',
}

export const DEFAULT_UI_MODE = UIMode.HighYield; // UIMode.InvestmentGrade

// User Preferences
export const DEFAULT_USER_PREFERENCES: UserPreferences = {
  uiMode: DEFAULT_UI_MODE,
  version: undefined as any, // adding version just to match ts type
}



export const ONE_MILLION = 1_000_000;
export const ONE_THOUSAND = 1_000;

export const percentileOptions = [
  '5',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
  '60',
  '65',
  '70',
  '75',
  '80',
  '85',
  '90',
  '95'
] as const;

export type Percentile = typeof percentileOptions[number];