import Table, { Column, TableProps } from "./table";
import { ColumnsOrderMap, TableName, UserPreferences } from "@/types/types";
import { useUiMode } from "@hooks/useUiMode";
import { captureException } from "@sentry/react";
import { toast } from "react-toastify";
import { DEFAULT_USER_PREFERENCES, LocalStorageKey } from "@/constants";
import useLocalStorage from "@hooks/useLocalStorage";
import { useGetUserPreferencesQuery } from "@/store/api/userPreferences.endpoints";
import { useSyncUserPreferences } from "@/app/userPreferences/hooks/useSyncUserPreferences";
import { useCallback, useEffect } from "react";
import { difference, intersection, isEqual } from "lodash";

// smart components which handles user preferences for the table by saving and providing data from preferences
export const TableWithUserPreferences = <T, Key extends string, Id extends string = string>(props: TableProps<T, Id> & {
  preferenceTableName: TableName;
  columnsOrderConfig: Record<Key, Id[]>
  preferenceKey?: Key, // by default we use uiMode as key, you can pass custom key in case if table should look same for all uiModes
}) => {
  const { uiMode } = useUiMode();
  const { preferenceTableName, columnsOrderConfig, preferenceKey, ...rest } = props;
  const { data: preferences } = useGetUserPreferencesQuery();
  const { updateUserPreferences, updateStoredQueryData } = useSyncUserPreferences({ errorMessage: 'Failed to update columns order, please try again' });


  const key = preferenceKey || uiMode as Key;
  const defaultColumnsOrder = columnsOrderConfig[key];

  const apiTableConfig  = preferences?.tableConfig?.[preferenceTableName]?.columnsOrder?.[key as keyof ColumnsOrderMap];
  const columnsOrder = apiTableConfig?.order as Id[] || defaultColumnsOrder; // use columns order from preferences or default from config

  const getUpdatedPreferences = useCallback((newOrder: Id[]): UserPreferences => {
    const updatedColumnsOrderMap: ColumnsOrderMap = {
      ...preferences?.tableConfig?.[preferenceTableName]?.columnsOrder,
      [key as keyof ColumnsOrderMap]: {
        order: newOrder,
        defaultOrder: defaultColumnsOrder,
      },
    } as ColumnsOrderMap

    const updatedPreferences: UserPreferences = {
      ...preferences as UserPreferences,
      tableConfig: {
        ...preferences?.tableConfig,
        [preferenceTableName]: {
          ...preferences?.tableConfig?.[preferenceTableName],
          columnsOrder: updatedColumnsOrderMap,
        } 
      }
    }

    return updatedPreferences;
  }, [key])

  
  const lastSavedDefaultOrder = apiTableConfig?.defaultOrder;
  useEffect(() => {
    if (!lastSavedDefaultOrder) {
      return;
    }


    // check if columns have same items -> order of the columns doesn't matter in this case
    const uniqueValues = intersection(lastSavedDefaultOrder, defaultColumnsOrder);
    const sameColumns = uniqueValues.length === lastSavedDefaultOrder.length && uniqueValues.length === defaultColumnsOrder.length;

    // update columns if they are not same
    if (!sameColumns) {
      // default columns order was changed, we need to check if there were any columns added or removed 

      const addedColumns = difference(defaultColumnsOrder, lastSavedDefaultOrder) as Id[];
      // TODO: implement removed columns when needed
      // const removedColumns = difference(lastSavedDefaultOrder, defaultColumnsOrder) as Id[];

      function findLinkedColumnIdx(column: Id, columnsOrder: Id[]): number {
        const columnIdx = defaultColumnsOrder.indexOf(column);
        if (columnIdx === 0) {
          return -1;
        }

        const prevIdx = columnIdx - 1;
        const prevColumn = defaultColumnsOrder[prevIdx];
        const linkedColumnIdx = columnsOrder.indexOf(prevColumn);
        return linkedColumnIdx === -1 ? findLinkedColumnIdx(prevColumn, columnsOrder) : linkedColumnIdx;
      }

      let newOrder = [...columnsOrder];
      addedColumns.forEach(column => {
        const linkedColumnIdx = findLinkedColumnIdx(column, newOrder);


        if (linkedColumnIdx === -1) {
          newOrder = [column, ...newOrder];
        } else {
          newOrder.splice(linkedColumnIdx + 1, 0, column)
        }
      })

      const updatedPreferences = getUpdatedPreferences(newOrder);
      updateStoredQueryData(updatedPreferences)
    }
  }, [lastSavedDefaultOrder, updateStoredQueryData])

  // handleOrderChange
  const handleOrderChange = async (items: Column<T, Id>[]) => {
    if (!preferences) {
      return;
    }

    try {
      const newOrder = items.map(item => item.id);
      const updatedPreferences = getUpdatedPreferences(newOrder);
      updateUserPreferences(updatedPreferences);
    } catch (error) {
      captureException(error);
      toast.error('Failed to update columns order, please try again');
    }
  }

  return (
    <Table 
      {...rest} 
      columnsOrder={columnsOrder} 
      onColumnsOrderChange={handleOrderChange}
    />
  )
}