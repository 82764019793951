import { CSSProperties, MouseEventHandler, ReactNode } from "react"
import clsx from "clsx"
import { XMarkIcon } from "@heroicons/react/20/solid";

export const Row = (props: { children: ReactNode, style?: CSSProperties; }) => <div {...props} className='flex flex-row justify-between w-full' />


export const Col = ({
  label,
  children,
  value,
  end,
  valueClassName,
  className,
  box,
  style,
  onClick,
}: {
  label: ReactNode
  children?: ReactNode
  value?: ReactNode
  end?: boolean;
  valueClassName?: string;
  className?: string;
  box?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
}) => {
  return (
    <div
      className={clsx('flex flex-col', className, {
        'items-end text-right': end,
        'cursor-pointer': onClick,
      })}
      onClick={onClick}
      style={style}
    >
      <span className="text-[#8183B3]">{label}</span>
      <span
        className={clsx('flex-1 flex items-center', valueClassName, {
          'px-[0.625rem] py-[0.375rem] rounded-[0.375rem]': box,
        })}
      >
        {value || children}
      </span>
    </div>
  )
}

export const DarkBox = ({ children }: { children: ReactNode }) => (
  <div
    className='bg-[#0A0B11] flex flex-col gap-[0.75rem] p-[0.625rem] rounded-[0.4375rem] w-full'
  >{children}</div>
)

export const DeleteButton = ({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) => {
  return (
    <div className="flex flex-row items-center justify-end mb-[-0.875rem] mr-[-1.5rem] mt-[-1.875rem] w-full">
      <button
        className="bg-transparent h-[1.5rem] flex flex-col items-center justify-center rounded-full w-[1.5rem]"
        onClick={onClick}
      >
        <XMarkIcon className="h-[1rem] text-[#4384C8]"/>
      </button>
    </div>
  )
}

export const ItemCard = {
  Row,
  Col,
  DarkBox,
}