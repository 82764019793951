import { Bond } from "@/app/data/bondIndex"
import { Col, Row } from "../itemCard"
import { formatAmountOutstanding } from "@/utils/number.utils"

export const BondCardData = ({ bond }: { bond: Bond }) => {
  return (
    <>
      <Row>
        <Col label="Ticker" value={bond.ticker} />
        <Col label="Coupon" value={`${bond.coupon.toFixed(3)}%`} end />
      </Row>

      <Row>
        <Col label="Maturity" value={bond.maturity} />
        <Col label="Series" value={bond.series || '-'} end />
      </Row>

      <Row>
        <Col label="CUSIP" value={bond.cusip} />
        <Col label="S&P" value={bond.rating} end />
      </Row>

      <Row>
        <Col label="Outstanding" value={formatAmountOutstanding(bond.amountOutstanding)} />
      </Row>

      <Row>
        <Col label="Name" value={bond.issuer} valueClassName="overflow-x-auto whitespace-nowrap w-[15.9375rem]" />
      </Row>
    </>
  )
}