import { QueryParam } from "@/constants";
import { memo } from "react";
import { NumericFormat } from "react-number-format";
import { useSearchParams } from "react-router-dom";

type Props = {
  value: string | number | null | undefined;
  className?: string,
  decimalScale?: number
}


export const FormattedGspread = (props: Props) => {
  // const [searchParams] = useSearchParams();
  // const roundPrice = searchParams.get(QueryParam.RoundPrice) === 'true';

  // const decimalScale = props.decimalScale 
  //   ? props.decimalScale 
  //   : roundPrice
  //     ? 0
  //     : 1;


  return (
    <Format {...props} />
  )
}


const Format = memo(({
  value,
  className,
  decimalScale = 0
}: Props) => {
  return (
    <NumericFormat
      allowLeadingZeros={false}
      value={value}
      displayType='text'
      defaultValue='-'
      thousandSeparator=","
      decimalScale={decimalScale}
      className={className}
    />
  )
})