import { Dispatch, FunctionComponent, ReactNode, SetStateAction, useId } from 'react';
import { classNames } from '../../..';
import ActionModal from './actionModal';

const ActionWithInputModal: FunctionComponent<{
  action: () => Promise<void | boolean>,
  actionName: string,
  body?: ReactNode,
  disabled?: boolean,
  label: string,
  placeholder: string,
  setShow: Dispatch<SetStateAction<boolean>>,
  setValue: Dispatch<SetStateAction<string>>,
  show: boolean,
  title?: string,
  value: string
}> = ({
  action,
  actionName,
  body,
  disabled,
  label,
  placeholder,
  setShow,
  setValue,
  show,
  title,
  value
}) => {

  const inputId = useId();

  return (
    <ActionModal
      action={action}
      actionName={actionName}
      body={
        <div className="w-full">
          { body || <></>}
          <label htmlFor={inputId} className="visually-hidden">{label}</label>
            <input
              className={classNames("bg-[#5D5F9D] border-0 px-[1.25rem] py-[0.625rem]",
                                    "ring-0 rounded-full",
                                    "text-[1.25rem] text-[#FBFBFD] w-full",
                                    "focus:ring-2 focus:ring-inset focus:ring-[#4384C8] placeholder:text-[#C9CADE]/[0.5]")}
              id={inputId}
              name={inputId}
              onChange={e => void setValue(e.target.value)}
              placeholder={placeholder}
              type="text"
              value={value} />
          </div>
      }
      disabled={disabled}
      onClose={() => setShow(false)}
      show={show}
      title={title}
    />
  );
}

export default ActionWithInputModal;
