import { NestedKeyOf } from "@/types/types";

export function createStringSort<T extends Object>(key: NestedKeyOf<T>, defaultValue: string = '') {
  const keyParts = key.split('.');
  const getValue = (obj: T) => keyParts.reduce((acc, keyPart) => (acc as any)[keyPart] , obj) as any as string;

  return (a: T, b: T) => (getValue(a) || defaultValue).localeCompare(getValue(b) || defaultValue);
}

export function createNumberSort<T extends Object>(key: NestedKeyOf<T>, defaultValue = 0) {
  const keyParts = key.split('.');
  const getValue = (obj: T) => {
    const value = keyParts.reduce((acc, keyPart) => (acc as any)[keyPart], obj);
    return typeof value === 'number' ? value : defaultValue;
  }
  return (a: T, b: T) => getValue(a) - getValue(b)
}
