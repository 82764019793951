import { FunctionComponent } from "react";
import { classNames } from "../..";
import { Position } from ".";
import { Bond, Side } from '../data/dataProvider';
import { getAsymptoticSpectrumBackground } from "../components/spectrum";
import { BondCardData } from "../components/data/bondCardData";
import { Col, DeleteButton, Row } from "../components/itemCard";
import { InferenceResult } from "@hooks/data/useSimpleInferenceData";
import { useUiMode } from "@hooks/useUiMode";
import { PortfolioColumn } from "./portfolio.constants";
import { getColumnTitle } from "./portfolio.utils";
import clsx from "clsx";

const PortfolioCard: FunctionComponent<{
  position: Position & Bond,
  inferenceResult: InferenceResult<Position & Bond>;
  remove: (figi: string) => void,
  selected?: boolean
}> = ({
  position,
  inferenceResult,
  remove,
  selected,
}) => {
    const gct = getColumnTitle('');
    const { isIgUiMode, isHyUiMode } = useUiMode();
    const { data } = inferenceResult;
    const d = data[position.figi];

    // bid gspd
    const bidGspdStr = d[Side.bid].gspread.currentString;
    const bidGspdChangeStr = d[Side.bid].gspread.diffString;
    const bidGspdChange = d[Side.bid].gspread.diff;

    // offer gspd
    const offerGSpdStr = d[Side.offer].gspread.currentString;

    // bid yield to maturity
    const bidYTMStr = d[Side.bid].ytm.currentString;
    const bidYTMChangeStr = d[Side.bid].ytm.diffString;
    const bidYTMChange = d[Side.bid].ytm.diff;

    // bid px
    const bidPxStr = d[Side.bid].price.currentString;
    const bidPxChangeStr = d[Side.bid].price.diffString;
    const bidPxChange = d[Side.bid].price.diff;

    // offer px
    const offerPxStr = d[Side.offer].price.currentString;

    return (
      <div className={classNames(selected ? "border-[#4384C8]" : "border-transparent", "bg-[#1F2034] border-2 flex flex-col gap-[0.75rem] items-center max-w-[17.1875rem] min-w-[17.1875rem] pb-[0.625rem] pt-[1.875rem] px-[0.625rem] rounded-[0.625rem] w-[17.1875rem] text-[0.875rem]")}>
        <DeleteButton onClick={e => { /* stop propogation since the card also has an onClick */ e.stopPropagation(); remove(position.figi); }} />
        <BondCardData bond={position} />


        <div className="bg-[#1F2034] flex flex-col gap-[0.75rem] p-[0.625rem] rounded-[0.4375rem] w-full">

          {/* IG mode fields */}
          {isIgUiMode && (
            <>
              <Row>
                <Col label={gct(PortfolioColumn.BidGSpd)} value={bidGspdStr} />
                <Col
                  label={gct(PortfolioColumn.BidGSpdChange)}
                  value={bidGspdChangeStr}
                  valueClassName={clsx(getAsymptoticSpectrumBackground(bidGspdChange), "px-[0.625rem] py-[0.375rem] rounded-[0.375rem]")}
                  box
                  end
                />
              </Row>

              <Row>
                <Col
                  label={gct(PortfolioColumn.OfferGSpd)}
                  value={offerGSpdStr}
                />
              </Row>

              <Row>
                <Col
                  label={gct(PortfolioColumn.BYTM)}
                  value={bidYTMStr}
                />
                <Col
                  label={gct(PortfolioColumn.BYTMChange)}
                  value={bidYTMChangeStr}
                  valueClassName={clsx(getAsymptoticSpectrumBackground(bidYTMChange), "px-[0.625rem] py-[0.375rem] rounded-[0.375rem]")}
                  box
                  end
                />
              </Row>
            </>
          )}

          {/* HY mode fields */}
          {isHyUiMode && (
            <>
              <Row>
                <Col
                  label={gct(PortfolioColumn.BidPx)}
                  value={bidPxStr}
                />
                <Col
                  label={gct(PortfolioColumn.BidPxChange)}
                  value={bidPxChangeStr}
                  valueClassName={clsx(getAsymptoticSpectrumBackground(bidPxChange), "px-[0.625rem] py-[0.375rem] rounded-[0.375rem]")}
                  box
                  end
                />
              </Row>

              <Row>
                <Col
                  label={gct(PortfolioColumn.OfferPx)}
                  value={offerPxStr}
                />
              </Row>
            </>
          )}

        </div>
      </div>
    )
  };

export default PortfolioCard;
