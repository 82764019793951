import { DateTime } from "luxon";
import { FinraHolidays } from "../app/data/dataProvider";

export function getFinraEarlyCloseSet(finraHolidays: FinraHolidays) {
  return finraHolidays 
    ? new Set(Object.getOwnPropertyNames(finraHolidays.early_close).map(d => DateTime.fromISO(d, { zone: 'America/New_York' }).toMillis())) 
    : new Set<number>()
}

export function getFinraHolidaySet(finraHolidays: FinraHolidays) {
  return finraHolidays 
    ? new Set(finraHolidays.holiday.map(d => DateTime.fromISO(d, { zone: 'America/New_York' }).toMillis())) 
    : new Set<number>()
}

export function getEndOfDayMs(dateMs: number, finraHolidays: FinraHolidays, earlyCloseSet: Set<number>) {
  return earlyCloseSet.has(dateMs)
      ? DateTime.fromISO(finraHolidays!.early_close[DateTime.fromMillis(dateMs, { zone: 'America/New_York' }).toISODate()!]).plus({ hour: 1 }).toMillis()
      : DateTime.fromMillis(dateMs, { zone: 'America/New_York' }).set({ hour: 18 }).toMillis()
}