import { useCallback, useMemo } from 'react';
import { useOpenPage } from '../../hooks/useOpenPage';
import { useCreatePortfolioObjectMutation, useLazyGetPortfolioQuery, useUpdatePortfolioObjectMutation } from '@/store/api/portfolio.endpoints';
import { captureException } from '@sentry/core';
import { toast } from 'react-toastify';
import { useDeleteObjectMutation } from '@/store/api/object.endpoints';

export type PortfolioValue = {
  positions: {
    figi: string;
    position: number;
  }[];
}

export type PortfolioResponse = {
  id: string;
  metadata: string;
  privilege: string;
  privilege_version: string;
  type: string;
  value: PortfolioValue;
  version: string;
}

export const useCreatePortfolio = () => {
  const openPage = useOpenPage();
  const [createPortfolioApi] = useCreatePortfolioObjectMutation();

  const createPortfolio = useCallback(async (name: string, figis: Set<string>) => {
    if (!name.length) {
      return;
    }

    try {
      const positions = Array.from(figis).map(figi => ({ figi, position: 1000000 }));
      const result = await createPortfolioApi({ name, positions }).unwrap();
      openPage.portfolio(result.id);
      return result;
    } catch (e) {
      captureException(e);
      toast.error('Failed to create portfolio, try again...');
    }

  }, [createPortfolioApi, openPage])

  return createPortfolio;
}


export const useAddToPortfolio = () => {
  const openPage = useOpenPage();
  const [getPortfolioApi] = useLazyGetPortfolioQuery();
  const [updatePortfolioApi] = useUpdatePortfolioObjectMutation();

  const addToPortfolio = useMemo(() =>
    async (id: string, figis: Set<string>) => {
      let portfolio: PortfolioResponse | null = null;
      try {
        portfolio = await getPortfolioApi(id).unwrap()
      } catch (e) {
        captureException(e);
        toast.error('Failed to add to portfolio, try again...');
        return;
      }


      const existingFigis = new Set(portfolio.value.positions.map(p => p.figi));
      const positions = [
        ...portfolio.value.positions,
        ...Array.from(figis).filter(figi => !existingFigis.has(figi)).map(figi => ({ figi, position: 1000000 }))
      ];

      try {
        const response = await updatePortfolioApi({
          id,
          positions,
          version: portfolio.version
        }).unwrap();
        openPage.portfolio(response.id)
        return response;
      } catch (e) {
        captureException(e);
        toast.error('Failed to add to portfolio, try again...');
      }
    }
  , [openPage, getPortfolioApi, updatePortfolioApi]);

  return addToPortfolio;
}


export const useRemoveFromPortfolio = () => {
  const [getPortfolioApi] = useLazyGetPortfolioQuery();
  const [updatePortfolioApi] = useUpdatePortfolioObjectMutation();

  const removeFromPortfolio = useCallback(
    async (id: string, figisToRemove: Set<string>) => {
      try {
        const portfolio = await getPortfolioApi(id).unwrap();
        const positions = portfolio.value.positions.filter(p => !figisToRemove.has(p.figi))
        await updatePortfolioApi({
          id,
          positions,
          version: portfolio.version
        });
      } catch (e) {
        captureException(e);
        toast.error('Failed to remove from portfolio, try again...');
        return false;
      }
    }
  , [getPortfolioApi, updatePortfolioApi]);

  return removeFromPortfolio;
}

export const useEditPortfolio = () => {
  const [getPortfolioApi] = useLazyGetPortfolioQuery();
  const [updatePortfolioApi] = useUpdatePortfolioObjectMutation();

  const editPortfolio = useCallback(
    async (id: string, name: string) => {
      try {
        const portfolio = await getPortfolioApi(id).unwrap();
        await updatePortfolioApi({
          id,
          metadata: { name },
          version: portfolio.version
        });
      } catch (e) {
        captureException(e);
        toast.error('Failed to edit portfolio, try again...');
        return false;
       }
    }
  , [getPortfolioApi, updatePortfolioApi]);

  return editPortfolio;
}

export const useDeletePortfolio = () => {
  const [deletePortfolioApi] = useDeleteObjectMutation();

  const deletePortfolio = useCallback(
    async (id: string) => {
      try {
        await deletePortfolioApi({ type: 'portfolio', id }).unwrap();
      } catch (e) {
        captureException(e);
        debugger;
        toast.error('Failed to delete portfolio, try again...');
        return false;
      }
    }
  , [deletePortfolioApi]);

  return deletePortfolio;
}